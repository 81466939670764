import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidget from "./mini-widget";
import { DashBoardAPI } from "../../apis/DashBoardAPI";
import { withTranslation } from "react-i18next"

const series1 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}];

const options1 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const PharmacyDashboard = (props) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getCount();
  }, []);


  const getCount = async () => {
    await DashBoardAPI.getCountPharmacyOwner()
      .then((res) => {
        let data = [
         
         
          {
            id: 1,
            icon: "",
            link:"/pharmacists_list",
            title:"Total Pharmacists",
            value: res.data.pharmacistCount,
            decimal: 0,
            charttype: "radialBar",
            chartheight: 45,
            chartwidth: 45,
            prefix: "",
            suffix: "",
            badgeValue: "",
            color: "danger",
            desc: "",
            series: series2,
            options: options2,
          },
          {
            id: 2,
            icon: "",
            link:"/pharmacy",
            title:"Total Checkin Today",
            value: res.data.checkinContById.count,
            decimal: 0,
            charttype: "radialBar",
            chartheight: 45,
            chartwidth: 45,
            prefix: "",
            suffix: "",
            badgeValue: "",
            color: "danger",
            desc: "",
            series: series2,
            options: options2,
          },
          {
            id: 3,
            icon: "",
            link:"/pharmacy",
            title:"Total Checkout Today",
            value: res.data.checkoutContById.count,
            decimal: 0,
            charttype: "radialBar",
            chartheight: 45,
            chartwidth: 45,
            prefix: "",
            suffix: "",
            badgeValue: "",
            color: "danger",
            desc: "",
            series: series2,
            options: options2,
          },
          {
            id: 4,
            icon: "",
            link:"/pharmacy",
            title:"Offline Pharmacists",
            value: res.data.pharmacistsOfflineCount,
            decimal: 0,
            charttype: "radialBar",
            chartheight: 45,
            chartwidth: 45,
            prefix: "",
            suffix: "",
            badgeValue: "",
            color: "danger",
            desc: "",
            series: series2,
            options: options2,
          },
          {
            id: 5,
            icon: "",
            link:"/pharmacy",
            title:"Online Pharmacists",
            value: res.data.pharmacistsOnlineCount,
            decimal: 0,
            charttype: "radialBar",
            chartheight: 45,
            chartwidth: 45,
            prefix: "",
            suffix: "",
            badgeValue: "",
            color: "danger",
            desc: "",
            series: series2,
            options: options2,
          }
        ]
        setReports(data)
      })
      .catch((err) => {
        console.log(err);
      });


  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Pharmacy Council")} breadcrumbItem={props.t("Dashboard (Pharmacy Owner)")} />
          <Row>
            <MiniWidget reports={reports} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default (withTranslation()(PharmacyDashboard))