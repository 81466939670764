import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const CheckinCheckoutAPI = {
    getAllCheckins: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-checkin-record`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    getAllCheckinsPharmacyOwner: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-checkin-record`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }

}