import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PageContentAPI } from "../../apis/PageContentAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"

// console.log(config.API_URL)
const PharmacyList = (props) => {
    const [page_content, setPageContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        limit: 10,

    });

    useEffect(() => {
        getPageCntent(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: row => row.sr_no,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Page Name"),
            selector: row => row.page_name,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },

        {
            name: props.t("Action"),
            selector: (row) => row.action,
            sort: "asc",
            width: 200,
        },
    ];

    const getPageCntent = (data) => {
        setLoading(true);
        PageContentAPI.getPageCntent(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.pageContent.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res.data.pageContent.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        sr_no: sr_no,
                        // thumbnail: ++i,

                        page_name: v.page_name,
                        action: (
                            <>
                                <a
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    href={`${config.API_URL}/pages/page-content?page_type=${v.page_type}`} target="_blank"
                                >
                                    <i className="mdi mdi-eye-outline font-size-18" style={{ color: "#7b8190" }} id="edittooltip" />
                                </a>
                                <Link
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    to={`/edit-page-content/${v.id}`}
                                >
                                    <i className="mdi mdi-pencil font-size-18" style={{ color: "#7b8190" }} id="edittooltip" />
                                </Link>
                            </>
                        ),
                    };
                });
                setPageContent(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        });

        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        }
        getPageCntent(data)
    }


    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        }
        getPageCntent(data)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("CMS")} />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <DataTableExtensions
                                        columns={columns}
                                        data={page_content}
                                        print={false}
                                        export={false}
                                        filter={false}
                                    >
                                        <DataTable
                                            className="table-bordered"
                                            noDataComponent={props.t('There are no records to display')}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable

                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(PharmacyList))