import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    CardSubtitle,
} from "reactstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GeneralSettingAPI } from "../../apis/GeneralSettingAPI";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"
const GeneralSetting = (props) => {
    const history = useHistory();
    const [general_setting_list, setGeneralSetting] = useState([]);
    const [key_name, setKeyName] = useState("");
    const [value_name, setValueName] = useState("");
    const [loading, setLoading] = useState(false);
    const [general_setting_id, setGeneralSettingId] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: 10,
    });


    useEffect(() => {
        getGeneralSetting(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: (row) => row.id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Name"),
            selector: (row) => row.key,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Distance"),
            selector: (row) => row.value,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Description"),
            selector: (row) => row.description,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Action"),
            selector: (row) => row.action,
            sort: "asc",
            width: 200,
        },

    ];

    const getGeneralSetting = (data) => {
        setLoading(true);
        GeneralSettingAPI.getAllGeneralSetting(data)
            .then((res) => {
                console.log(res)
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.generalSetting.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                res.data.generalSetting.rows.forEach((v, i) => {
                    data[i] = {
                        // srno: ++i,
                        id: v.id,
                        key: v.key,
                        value: v.value,
                        description: v.description,
                        action: (
                            <>
                                <i
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    className="mdi mdi-pencil font-size-18"
                                    id="edittooltip"
                                    onClick={() => onEdit(v.id)}
                                />
                            </>
                        ),

                    };
                });
                setGeneralSetting(data);
                setLoading(false);

            })
            .catch((err) => {
                console.log(err);
            });

    };

    const onEdit = (general_setting_id) => {
        GeneralSettingAPI.getGeneralSettingId(general_setting_id)
            .then((res) => {
                console.log(res)
                setKeyName(res.data.generalSetting.key);
                setValueName(res.data.generalSetting.value);
                setGeneralSettingId(res.data.generalSetting.id);
                getGeneralSetting(activeData);

            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        });
        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        };
        getGeneralSetting(data);
    };

    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        };
        getGeneralSetting(data);
    };
    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            key_name: key_name,
            value_name: value_name,
        },
        validationSchema: Yup.object({
            key_name: Yup.string().required(props.t("Please Enter Key  Name ")),
            value_name: Yup.string().required(props.t("Please Enter Value ")),
        }),
        onSubmit: (values) => {
            values.general_setting_id = general_setting_id;
            GeneralSettingAPI.updateGeneralSetting(values)
                .then((res) => {
                    // setLoader(false)
                    Swal.fire({
                        text: props.t("General Setting Updated Successfully"),
                        icon: "success",
                        imageAlt: "success image",
                        // confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/general_setting");
                            getGeneralSetting(activeData);
                            window.location.reload(false);
                        }
                    });
                })
                .catch(function (error) {
                    // setLoader(false)
                    Swal.fire({
                        text: error?.response?.data?.message,
                        icon: "error",
                        imageAlt: "error image",
                        // confirmButtonColor: '#00CA84'
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Table")} breadcrumbItem={props.t("General Setting")} />
                    <Row>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                    <CardSubtitle className="mb-3">
                                        {props.t("General Setting")}
                                    </CardSubtitle>

                                    <DataTableExtensions 
                                    columns={columns} 
                                    data={general_setting_list} 
                                    filter={false}
                                    print={false} export={false}>
                                        <DataTable
                                            className="table-bordered"
                                            noDataComponent={props.t('There are no records to display')}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={(value) => handleRowChange(value)}
                                            onChangePage={(value) => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Type")}</Label>
                                                    <Input
                                                        name="key_name"
                                                        readOnly={true}
                                                        placeholder={props.t("Type")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.key_name || ""}
                                                        invalid={
                                                            validation.touched.key_name &&
                                                                validation.errors.key_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.key_name &&
                                                        validation.errors.key_name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.key_name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Distance")}</Label>
                                                    <Input
                                                        name="value_name"
                                                        placeholder={props.t("Distance")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.value_name || ""}
                                                        invalid={
                                                            validation.touched.value_name &&
                                                                validation.errors.value_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.value_name &&
                                                        validation.errors.value_name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.value_name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit">
                                            {props.t("Update")}
                                        </Button>

                                        {"  "}
                                        <Button color="light" onClick={() => window.location.reload(false)}>
                                            {props.t("Cancel")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
export default (withTranslation()(GeneralSetting))
