import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const PharmacyTypeAPI = {
    getAllPharmacyTypeList: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacy-type`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createPharmacyType: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/create-pharmacy-type`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getPharmacyType: async (pharmacy_type_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-pharmacy-type/${pharmacy_type_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    updatePharmacyType: async (data) => {
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-pharmacy-type`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deletePharmacyType: async (pharmacy_type_id) => {
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}/api/admin/delete-pharmacy-type/${pharmacy_type_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }

}