import config from "../config";
import axios from "axios"
import { getToken } from "../Token";
const token = getToken();

export const GeneralSettingAPI = {
    getAllGeneralSetting: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-general-setting`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getGeneralSettingId: async (general_setting_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-general-setting/${general_setting_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getGeneralSettingId: async (general_setting_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-general-setting/${general_setting_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    
    updateGeneralSetting: async (data) => {
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-general-setting`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    
   

}