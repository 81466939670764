import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Form,
    Button,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import { PharmacyListAPI } from "../../apis/PharmacyListAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"
import toastr from "toastr"
import pharmacist_image from "../../assets/images/flags/pharmacist image.jpg";

import * as Yup from "yup";
import { useFormik } from "formik";

const PharmacyList = (props) => {
    const [pharmacies, setPharmacy] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        limit: 10,
        search: "",

    });

    useEffect(() => {
        getPharmacy(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: row => row.sr_no,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Photo"),
            selector: row => row.thumbnail,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Store Permit Number"),
            selector: row => row.store_permit_number,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.pharmacy_name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Mobile"),
            selector: row => row.mobile,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Pharmacy Type"),
            selector: row => row.pharmacy_type,
            sort: "asc",
            width: 200,
        },
        {
            name: props.t("Action"),
            selector: (row) => row.action,
            sort: "asc",
            width: 200,
        },
    ];

    const getPharmacy = async (data) => {
        setLoading(true);
        await PharmacyListAPI.getAllPharmacyRecord(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.response.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res.data.response.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        sr_no: sr_no,

                        // thumbnail: (
                        //     <>
                        //     {
                        //         v?.User.user_image != undefined &&
                        //         <img
                        //             className=" header-profile-user"
                        //             src={`${config.API_URL}/${v.User.user_image}`}

                        //         />
                        //     }
                        //     </>
                        // ),
                        thumbnail: (
                            <>
                                {
                                    v?.User?.user_image ?
                                        <img
                                            className=" header-profile-user"
                                            src={v.User.user_image}

                                        /> :
                                        <img
                                            className=" header-profile-user"
                                            src={pharmacist_image}

                                        />
                                }
                            </>
                        ),
                        store_permit_number: v.permit_number,
                        pharmacy_name: v.pharmacy_name,
                        mobile: v.mobile,
                        pharmacy_type: v.prarmacy_type,
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    to={`/edit-pharmacy/${v.user_id}`}
                                >
                                    <i className="mdi mdi-pencil font-size-18" style={{ color: "#7b8190" }} />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "#7b8190" }} onClick={() => onDelete(v.user_id)} />&nbsp;&nbsp;&nbsp;
                                <i className="mdi mdi-lock-reset font-size-18" style={{ color: "#7b8190" }} onClick={() => onClickCopy(v.User.read_password)} title="View Password" />&nbsp;&nbsp;&nbsp;
                                <i className="mdi mdi-email font-size-18" style={{ color: "#7b8190" }} onClick={() => sendEmailForPassword(v.User)} title="Send Password to email." />
                            </>
                        ),
                    };
                });
                setPharmacy(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        });

        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        }
        getPharmacy(data)
    }


    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        }
        getPharmacy(data)
    }




    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are you sure?"),
            text: props.t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes, delete it!"),
        }).then((result) => {
            if (result.isConfirmed) {
                PharmacyListAPI.deletePharmacy(id)
                    .then((res) => {
                        Swal.fire("Deleted!", res.data.message, "success");
                        getPharmacy(activeData);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onClickCopy = async (readPassword) => {
        // try {
        //     await navigator.clipboard.writeText(readPassword)
        // } catch (e) {
        //     console.log(e);
        // }
        Swal.fire({
            // title: props.t("Password Copied!"),
            title: props.t("Password is: " + readPassword),
            icon: "info",
        })
    };

    const sendEmailForPassword = async (user) => {
        PharmacyListAPI.sendEmailForPassword(user.id)
            .then(res => {
                Swal.fire({
                    // title: props.t("Password Copied!"),
                    title: props.t(res.data.message),
                    icon: "info",
                })
            }).catch(err => {
                console.log(err)
            })
    };


    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            permit_number: "",
            pharmacy_name: ""
        },
        validationSchema: Yup.object({
            // search_keyword: Yup.string().required()

        }),
        onSubmit: (values) => {
            activeData.activePage = 1;
            activeData.totalPage = 1;
            activeData.search = values;
            activeData.limit = 10;
            setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
            getPharmacy(activeData);

        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("Pharmacy List")} />

                    <Row>
                        {/* <Col className="col-12">
                            <Link to="/add-pharmacy">
                                <Button
                                    style={{ float: "right", marginBottom: "20px" }}
                                    color="primary"
                                    className="waves-effect waves-light  pull-right"
                                >
                                    {props.t("Add Pharmacy")}
                                </Button>
                            </Link>
                        </Col> */}

                        <Col className="col-12">
                            <Card>
                                <CardBody>







                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>


                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Store Permit No")}
                                                    </Label>
                                                    <Input
                                                        name="permit_number"
                                                        placeholder={props.t("Store Permit No")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.permit_number || ""}
                                                    // onChangePage={(value) => handleChangeStartdate(value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Pharmacy Name")}
                                                    </Label>
                                                    <Input
                                                        name="pharmacy_name"
                                                        placeholder={props.t("Pharmacy Name")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacy_name || ""}
                                                    // onChangePage={(value) => handleChangeStartdate(value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" className="mt-4" style={{ paddingTop: 5 }}>
                                                <Button color="primary"
                                                // onClick={handleClickCustomSearch()}
                                                >
                                                    {props.t("Search")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>






                                    <DataTableExtensions
                                        columns={columns}
                                        data={pharmacies}
                                        print={false}
                                        export={false}
                                        filter={false}
                                    >
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            noDataComponent={props.t('There are no records to display')}
                                            // columns={columns}
                                            // data={roles}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(PharmacyList))