import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const ProvinceAPI = {
    getProvincesList: async () => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-province`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }
}