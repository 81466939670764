import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { isLoggedIn, getRole } from "../../Token";

const SidebarContent = (props) => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });
  const checkUser = () => {
    if (!isLoggedIn()) {
      window.location.href = '/login';
    } else if (getRole() == 'Super Admin') {
      window.location.href = '/admin';
    } else if (getRole() == 'Pharmacy Owner') {
      window.location.href = '/pharmacy';
    } else {
      window.location.href = '/';
    }
  }
  const isAdmin = isLoggedIn() && getRole() == 'Super Admin';
  const isPhamcyOwner = isLoggedIn() && getRole() == 'Pharmacy Owner';

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          {
        isAdmin && (
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/admin" className="waves-effect">
                    <i className="mdi mdi-18px mdi-monitor-dashboard"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/" className="has-arrow waves-effect">
                    <i className="mdi mdi-18px mdi-card-account-details-outline"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pharmacists">{props.t("Manage Pharmacist")}</Link>
                    </li>
                    <li>
                      <Link to="/pharmacies">{props.t("Manage Pharmacy")}</Link>
                    </li>
                    <li>
                      <Link to="/pharmacy-type">{props.t("Pharmacy Type")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/customers">
                    <i className="uil-users-alt"></i>
                    <span>{props.t("Customer")}</span>
                  </Link>
                </li>

               
                <li>
                  <Link to="/rating_and_reviews" className="waves-effect">
                    <i className="mdi mdi-18px mdi-star"></i>
                    <span>{props.t("Rating And Reviews")}</span>
                  </Link>
                </li>

              

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-18px mdi-poll"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pharmacy_reports">{props.t("Pharmacy Report")}</Link>
                    </li>
                    <li>
                      <Link to="/pharmacist_reports">{props.t("Pharmacist Report")}</Link>
                    </li>
                    <li>
                      <Link to="/customers">{props.t("Customer Report")}</Link>
                    </li>
                    <li>
                      <Link to="checkin_checkout">{props.t("Checkin/Checkout Report")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/general_setting" className="waves-effect">
                    <i className="mdi mdi-18px mdi-shield-lock"></i>
                    <span>{props.t("General Setting")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/id-card-instructions" className="waves-effect">
                    <i className="mdi mdi-18px mdi-card-account-details"></i>
                    <span>{props.t("Id Card Instruction")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/complaint" className="waves-effect">
                    <i className="mdi mdi-18px mdi-note-multiple"></i>
                    <span>{props.t("Complaint")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="waves-effect">
                    <i className="mdi mdi-18px mdi-book-open-page-variant"></i>
                    <span>{props.t("CMS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/setting" className="waves-effect">
                    <i className="mdi mdi-18px mdi-book-open-page-variant"></i>
                    <span>{props.t("Setting")}</span>
                  </Link>
                </li>
              </ul>
            </div>
             )
            }
          </SimpleBar>
       
     
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          {
        isPhamcyOwner && (
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                  <Link to="/pharmacy" className="waves-effect">
                    <i className="mdi mdi-18px mdi-monitor-dashboard"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/" className="has-arrow waves-effect">
                    <i className="mdi mdi-18px mdi-card-account-details-outline"></i>
                    <span>{props.t("Master")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pharmacists_list">{props.t("Manage Pharmacist")}</Link>
                    </li>
                    <li>
                      <Link to="/pharmacy_list">{props.t("Manage Pharmacy")}</Link>
                    </li>
                  
                  </ul>
                </li>

                <li>
                  <Link to="/checkin_checkout_list" className="waves-effect">
                    <i className="mdi mdi-18px mdi-distribute-horizontal-center"></i>
                    <span>{props.t("Checkin/Checkout")}</span>
                  </Link>
                </li>
              
                <li>
                  <Link to="/banner_list" className="waves-effect">
                    <i className="mdi mdi-18px mdi-sticker"></i>
                    <span>{props.t("Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/rating_and_reviews_list" className="waves-effect">
                    <i className="mdi mdi-18px mdi-star"></i>
                    <span>{props.t("Rating And Reviews")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/qr_code" className="waves-effect">
                    <i className="mdi mdi-18px mdi-barcode"></i>
                    <span>{props.t("QR Code")}</span>
                  </Link>
                </li>
              </ul>
            </div>
              )
            }
          </SimpleBar>
      

    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
