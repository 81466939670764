import React, { useState } from 'react'
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI"
import { withTranslation } from "react-i18next";


const Setting = (props) => {

    const [date, setDate] = useState();
    const [loading, setLoading] = useState(false);

    const uploadAPIAllPhamacy = () => {
        setLoading(true);
        PharmacyListAPI.getAPIAllPharmacies()
            .then(res => {
                console.log('res', res)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log('err', err)
            })
    }

    const uploadAPIPhamacyWithDate = () => {
        setLoading(true);
        if (date != '') {
            PharmacyListAPI.getAPIPharmaciesWithDate(date)
                .then(res => {
                    console.log('res', res)
                    setLoading(false);
                }).catch(err => {
                    console.log('err', err)
                    setLoading(false);
                })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Dashboard")} breadcrumbItem={props.t("Setting")} />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <p className='text-center'>{loading && 'Loading...'}</p>
                                <CardBody>

                                    <Button
                                        type="button"
                                        color="success"
                                        className="waves-effect waves-light mb-3 btn btn-success"
                                        onClick={uploadAPIAllPhamacy}
                                        disabled={loading}
                                    >
                                        <i className="mdi mdi-plus me-1" />
                                        {props.t("Upload All Pharmacy")}
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <input
                                        className="form-control"
                                        type="date"
                                        onChange={(e) => {
                                            setDate(e.target.value);
                                        }}
                                        disabled={loading}
                                    />
                                    <Button
                                        type="button"
                                        color="success"
                                        className="waves-effect waves-light mb-3 btn btn-success"
                                        onClick={uploadAPIPhamacyWithDate}
                                    >
                                        <i className="mdi mdi-plus me-1" />
                                        {props.t("Upload Pharmacy With Date")}
                                        
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default (withTranslation()(Setting))