import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import { IdCardInstructionAPI } from "../../apis/IdCardInstructionAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"

const IdCardInstructionList = (props) => {
    const [id_card_instruction, setIdCardInstruction] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        limit: 10,

    });

    useEffect(() => {
        getAllIdCardInstruction(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: row => row.sr_no,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Email"),
            selector: row => row.email,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Instruction (Eng)"),
            selector: row => row.instruction_en,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: (row) => row.action,
            sort: "asc",
            width: 200,
        },
    ];

    const getAllIdCardInstruction = (data) => {
        setLoading(true);
        IdCardInstructionAPI.getAllIdCardInstruction(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res?.data?.idCardInstruction?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res?.data?.sr_no_start;
                res?.data?.idCardInstruction?.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        sr_no: sr_no,
                        // instruction_en: ++i,
                        instruction_en: v.instruction_en,
                        email: v.email,
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    to={`/edit-id-card-instruction/${v.id}`}
                                >
                                    <i className="mdi mdi-pencil font-size-18" style={{ color: "#7b8190" }} id="edittooltip" />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "#7b8190" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setIdCardInstruction(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        });

        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit
        }
        getAllIdCardInstruction(data)
    }


    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v
        }
        getAllIdCardInstruction(data)
    }

    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are you sure?"),
            text: props.t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes, delete it!"),
        }).then((result) => {
            if (result.isConfirmed) {
                IdCardInstructionAPI.deleteIdCardInstruction(id)
                    .then((res) => {
                        Swal.fire("Deleted!", res.data.message, "success");
                        getAllIdCardInstruction(activeData);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("Id Card Instruction List")} />

                    <Row>
                        <Col className="col-12">
                            <Link to="/add-id-card-instruction">
                                <Button
                                    style={{ float: "right", marginBottom: "20px" }}
                                    color="primary"
                                    className="waves-effect waves-light  pull-right"
                                >
                                    {props.t("Add Id Card Instruction")}
                                </Button>
                            </Link>
                        </Col>

                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <DataTableExtensions
                                        columns={columns}
                                        data={id_card_instruction}
                                        print={false}
                                        export={false}
                                        filter={false}
                                    >
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            noDataComponent={props.t('There are no records to display')}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable

                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(IdCardInstructionList))