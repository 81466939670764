import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, FormFeedback, Form } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI"
import { DistrictAPI } from "../../apis/DistrictAPI";
import { SubDistrictAPI } from "../../apis/SubDistrictAPI";
import Swal from 'sweetalert2';
import { useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import config from "../../config";

const EditPharmacy = (props) => {
    const history = useHistory();
    const pharmacy_id = useParams().pharmacy_id;
    const [pharmacy_name, setPharmacyName] = useState('')
    const [pharmacy_name_th, setPharmacyNameTh] = useState('')
    const [prarmacy_type, setPrarmacyType] = useState('')
    const [permit_owner, setPermitOwner] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [license_number, setLicenseNumber] = useState('')
    const [store_permit_no, setStore_Permit_No] = useState('')
    const [mobile, setMobile] = useState()
    const [user_image, setUserImage] = useState()
    const [contract_number, setContractNumber] = useState()
    const [contract_number_2, setContractNumber2] = useState()
    const [line_id, setLineId] = useState()
    const [website_url, setWebsiteUrl] = useState('')
    const [files1, setFiles1] = useState([]);
    const [pharmacy_type, setPharmacyType] = useState([]);
    const [provinces, setProvince] = useState([]);
    // const [district_id, setDistrict_id] = useState({ districtData: [], districtAllData: [] });
    // const [sub_district_id, setSubDistrict_id] = useState({ subDistrictData: [], subDistrictAllData: [] });
    const [province_id, setProvinceId] = useState();
    const [district, setDistrict] = useState();
    const [sub_district, setSubDistrict] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [zip_code, setZipCode] = useState();
    const [moo, setMoo] = useState();
    const [road, setRoad] = useState();
    const [village, setVillage] = useState();
    const [address, setAddress] = useState();


    const profileDetail = <h1>{props.t("Profile Detail")}</h1>;
    const pharmacyDetail = <h1>{props.t("Pharmacy Detail")}</h1>;
    useEffect(() => {
        getPharmacy()
    }, [])

    const getPharmacy = () => {
        PharmacyListAPI.getPharmacy(pharmacy_id)
            .then(res => {
                setPharmacyName(res.data.pharmacy.pharmacy_name)
                setPharmacyNameTh(res.data.pharmacy.pharmacy_name_th)
                setPrarmacyType(res.data.pharmacy.prarmacy_type)
                setPermitOwner(res.data.pharmacy.permit_owner)
                setMobile(res.data.pharmacy.mobile)
                setContractNumber(res.data.pharmacy.contract_number)
                setContractNumber2(res.data.pharmacy.contract_number_2)
                setEmail(res.data.user.email)
                setUserImage(res.data.user.user_image)
                setPassword(res.data.user.password)
                setProvinceId(res.data.pharmacy.province_id)
                setDistrict(res.data.pharmacy.district)
                setSubDistrict(res.data.pharmacy.subdistrict)
                setLicenseNumber(res.data.user.license_number)
                setStore_Permit_No(res.data.user.store_permit_no)
                setLineId(res.data.pharmacy.line_id)
                setLatitude(res.data.pharmacy.latitude)
                setLongitude(res.data.pharmacy.longitude)
                setZipCode(res.data.pharmacy.zip_code)
                setMoo(res.data.pharmacy.moo)
                setRoad(res.data.pharmacy.road)
                setVillage(res.data.pharmacy.village)
                setAddress(res.data.pharmacy.address)
                setWebsiteUrl(res.data.pharmacy.website_url)
                setPharmacyType(res.data.pharmacyType)
                setProvince(res.data.province.rows)
                // setDistrict({ districtData: res?.data?.district?.rows, districtAllData: res?.data?.district?.rows })
                // setSubDistrict({ subDistrictData: res?.data?.subDistrict?.rows, subDistrictAllData: res?.data?.subDdistrict?.rows })
            }).catch(err => {
                console.log(err)
            })
    }

    const handleChangeProvince = (province_id) => {

        // validation.setFieldValue("province_id", province_id);
        // validation.setFieldValue("district", "");
        setProvinceId(province_id)
        // if (province_id) {
        //     DistrictAPI.getAllDistrictByProvince(province_id)
        //         .then((res) => {
        //             setDistrict({ districtData: res?.data?.district?.rows, districtAllData: res?.data?.district?.rows });
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // }
    };

    // const handleChangeDistrict = (district) => {
    //     // validation.setFieldValue("district", district);
    //     // validation.setFieldValue("sub_district", "");
    //     if (province_id && district) {
    //         SubDistrictAPI.getAllSubDistrictByProvinceAndDistrict(district, province_id)
    //             .then((res) => {
    //                 setSubDistrict({ subDistrictData: res?.data?.subDistrict?.rows, subDistrictAllData: res?.data?.subDdistrict?.rows });
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }

    // };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            pharmacy_name: pharmacy_name,
            pharmacy_name_th: pharmacy_name_th,
            prarmacy_type: prarmacy_type,
            permit_owner: permit_owner,
            mobile: mobile,
            email: email,
            password: password,
            license_number: license_number,
            store_permit_no: store_permit_no,
            line_id: line_id,
            website_url: website_url,
            province_id: province_id,
            district: district,
            sub_district: sub_district,
            address: address,
            latitude: latitude,
            longitude: longitude,
            zip_code: zip_code,
            moo: moo,
            road: road,
            village: village,
            contract_number: contract_number,
            contract_number_2: contract_number_2,
        },
        validationSchema: Yup.object({
            pharmacy_name: Yup.string().required(props.t("Pharmacy Name is required")),//.matches(/^[aA-zZ\s]+$/, props.t("Only alphabets are allowed for this field ")),
            pharmacy_name_th: Yup.string().required(props.t("Pharmacy Name Thai is required")),
            email: Yup.string().required(props.t("Email is required")),
            password: Yup.string().required(props.t("Password is required")),
            mobile: Yup.string().required(props.t("Mobile is required")),//.matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            contract_number: Yup.string().required(props.t("Contact Number is required")),//.matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            // user_id: Yup.string().required(props.t("User name is required")),
            // website_url: Yup.string().matches(
            //     /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
            //     props.t('Please Enter Correct url!')
            // ).required(props.t("Website Url is required")),
            license_number: Yup.string().required(props.t("License Number is required")),
            permit_owner: Yup.string().required(props.t("Permit Owner is required")),//.matches(/^[aA-zZ\s]+$/, props.t("Only alphabets are allowed for this field ")),
            store_permit_no: Yup.string().required(props.t("store_permit_no is required")),
            latitude: Yup.string().required(props.t("Latitude is required")).matches(/^[+-]?\d+(\.\d+)?$/, props.t("Only Number are Allowed For This Field")),
            zip_code: Yup.string().required(props.t("Zip Code is required")).matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            longitude: Yup.string().required(props.t("Longitude is required")).matches(/^[+-]?\d+(\.\d+)?$/, props.t("Only Number are Allowed For This Field")),
            line_id: Yup.string().required(props.t("Line Id is required")),
            province_id: Yup.string().required(props.t("Province is required")),
            district: Yup.string().required(props.t("District is required")),
            // sub_district: Yup.string().required(props.t("Sub District is required")),
            prarmacy_type: Yup.string().required(props.t("Prarmacy Type is required")),
            address: Yup.string().required(props.t("Address is required")),

        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('user_image', files1);
            data.append('pharmacy_name', values.pharmacy_name);
            data.append('pharmacy_name_th', values.pharmacy_name_th);
            data.append('license_number', values.license_number);
            data.append('store_permit_no', values.store_permit_no);
            data.append('mobile', values.mobile);
            data.append('email', values.email);
            // data.append('password', values.password);
            data.append('line_id', values.line_id);
            data.append('prarmacy_type', values.prarmacy_type);
            data.append('permit_owner', values.permit_owner);
            data.append('contract_number', values.contract_number);
            data.append('contract_number_2', values.contract_number_2);
            data.append('website_url', values.website_url);
            data.append('pharmacy_id', pharmacy_id);
            data.append('province_id', values.province_id);
            data.append('district', values.district);
            data.append('sub_district', values.sub_district);
            data.append('latitude', values.latitude);
            data.append('longitude', values.longitude);
            data.append('zip_code', values.zip_code);
            data.append('moo', values.moo);
            data.append('road', values.road);
            data.append('village', values.village);
            data.append('address', values.address);
            PharmacyListAPI.updatePharmacy(data)
                .then(res => {
                    Swal.fire({
                        text: props.t("Pharmacy Updated Successfully"),
                        icon: 'success',
                        imageAlt: 'success image',
                        // confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/pharmacies');
                        }
                    });
                }).catch(function (error) {
                    // setLoader(false)
                    Swal.fire({
                        text: error?.response?.data?.message,
                        icon: 'error',
                        imageAlt: 'error image',
                        // confirmButtonColor: '#00CA84'
                    });
                });

        }
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Forms")} breadcrumbItem={props.t("Edit Pharmacy")} />
                    <Row>
                        <Col className="col-12">

                            <Button style={{ float: "right", marginBottom: "20px", }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>

                        </Col>

                        <Col xl="12" >
                            <Card>
                                <CardBody>

                                    <Form className="needs-validation"
                                    // onSubmit={(e) => {
                                    //     e.preventDefault();
                                    //     validation.handleSubmit();
                                    //     return false;
                                    // }}
                                    >
                                        <Row style={{ marginBottom: "20px", }}>
                                            {profileDetail}
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Permit Owner")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="permit_owner"
                                                        placeholder={props.t("Enter Permit Owner")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.permit_owner || ""}
                                                        invalid={
                                                            validation.touched.permit_owner &&
                                                                validation.errors.permit_owner
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.permit_owner &&
                                                        validation.errors.permit_owner ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.permit_owner}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Password")}</Label>
                                                    <Input
                                                        name="password"
                                                        placeholder={props.t("Enter Password")}
                                                        type="password"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password &&
                                                                validation.errors.password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.password &&
                                                        validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col> */}
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Email")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="email"
                                                        placeholder={props.t("Enter Email")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email &&
                                                                validation.errors.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                        validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("License")}</Label>
                                                    <Input
                                                        name="license_number"
                                                        placeholder={props.t("Enter License")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.license_number || ""}
                                                        invalid={
                                                            validation.touched.license_number &&
                                                                validation.errors.license_number
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.license_number &&
                                                        validation.errors.license_number ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.license_number}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col> */}
                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Mobile")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="mobile"
                                                        placeholder={props.t("Enter Mobile Number")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mobile || ""}
                                                        invalid={
                                                            validation.touched.mobile &&
                                                                validation.errors.mobile
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.mobile &&
                                                        validation.errors.mobile ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.mobile}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="5">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Upload Pharmacy Image")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="user_image"
                                                        placeholder={props.t("Enter Pharmacy Image Url")}
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFiles1(e.target.files[0]);
                                                            validation.setFieldValue('user_image', e.target.files[0]);
                                                        }}
                                                        invalid={
                                                            validation.touched.user_image &&
                                                                validation.errors.user_image
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    {/* <img
                                    className=" header-profile-user"
                                    src={`${config.API_URL}/${user_image}`}

                                /> */}
                                                    {validation.touched.user_image &&
                                                        validation.errors.user_image ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.user_image}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="1">
                                                {/* <FormGroup className="mb-3"> */}


                                                {/* <img
                                                    className=" header-profile-user" style={{
                                                        marginTop: '28px',
                                                        height: '40px',
                                                        width: '40px'
                                                    }}
                                                    src={`${config.API_URL}/${user_image}`}

                                                /> */}

                                                {/* </FormGroup> */}
                                            </Col>
                                            {pharmacyDetail}
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Name")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="pharmacy_name"
                                                        placeholder={props.t("Enter Pharmacy Name")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacy_name || ""}
                                                        invalid={
                                                            validation.touched.pharmacy_name &&
                                                                validation.errors.pharmacy_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.pharmacy_name &&
                                                        validation.errors.pharmacy_name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.pharmacy_name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Name Thai")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="pharmacy_name_th"
                                                        placeholder={props.t("Enter Pharmacy Name Thai")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacy_name_th || ""}
                                                        invalid={
                                                            validation.touched.pharmacy_name_th &&
                                                                validation.errors.pharmacy_name_th
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.pharmacy_name_th &&
                                                        validation.errors.pharmacy_name_th ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.pharmacy_name_th}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row >
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Pharmacy Type")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="prarmacy_type"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.prarmacy_type || ""}
                                                        invalid={
                                                            validation.touched.prarmacy_type &&
                                                                validation.errors.prarmacy_type
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Pharmacy Type")}</option>
                                                        {pharmacy_type?.rows?.map((item) => (

                                                            <option key={item.id} value={item.name_en}>{item.name_en}</option>

                                                        ))}
                                                    </Input>
                                                    {validation.touched.prarmacy_type &&
                                                        validation.errors.prarmacy_type ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.prarmacy_type}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Store Permit Number")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="store_permit_no"
                                                        placeholder={props.t("Enter Store Permit Number")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.store_permit_no || ""}
                                                        invalid={
                                                            validation.touched.store_permit_no &&
                                                                validation.errors.store_permit_no
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.store_permit_no &&
                                                        validation.errors.store_permit_no ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.store_permit_no}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Contact Number")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="contract_number"
                                                        placeholder={props.t("Enter Contact Number")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contract_number || ""}
                                                        invalid={
                                                            validation.touched.contract_number &&
                                                                validation.errors.contract_number
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.contract_number &&
                                                        validation.errors.contract_number ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contract_number}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Contact Number 2")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="contract_number_2"
                                                        placeholder={props.t("Enter Contact Number 2 ")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contract_number_2 || ""}
                                                        invalid={
                                                            validation.touched.contract_number_2 &&
                                                                validation.errors.contract_number_2
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.contract_number_2 &&
                                                        validation.errors.contract_number_2 ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contract_number_2}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>


                                        </Row>

                                        <Row >
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Website Url")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="website_url"
                                                        placeholder={props.t("Enter Website Url")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.website_url || ""}
                                                        invalid={
                                                            validation.touched.website_url &&
                                                                validation.errors.website_url
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.website_url &&
                                                        validation.errors.website_url ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.website_url}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Line Id")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="line_id"
                                                        placeholder={props.t("Enter Line Id")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.line_id || ""}
                                                        invalid={
                                                            validation.touched.line_id &&
                                                                validation.errors.line_id
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.line_id &&
                                                        validation.errors.line_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.line_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Province")}
                                                    </Label>

                                                    <Input
                                                        disabled={true}
                                                        name="province_id"
                                                        type="select"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onChange={(e) => handleChangeProvince(e.target.value)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.province_id || ""}
                                                        invalid={
                                                            validation.touched.province_id &&
                                                                validation.errors.province_id
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Province")}</option>
                                                        {provinces?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name_en}</option>
                                                        ))}
                                                    </Input>

                                                    {validation.touched.province_id &&
                                                        validation.errors.province_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.province_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("district")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="district"
                                                        placeholder={props.t("Enter District")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.district || ""}
                                                        invalid={
                                                            validation.touched.district &&
                                                                validation.errors.district
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    {/* <select
                                                        name="district"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={(e) => handleChangeDistrict(e.target.value)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.district || ""}
                                                        invalid={
                                                            validation.touched.district &&
                                                                validation.errors.district
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select District")}</option>
                                                        {district?.districtData?.map((item) => (

                                                            <option key={item.id} value={item.id}>{item.name_en}</option>

                                                        ))}
                                                    </select> */}
                                                    {validation.touched.district &&
                                                        validation.errors.district ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.district}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Subdistrict")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="sub_district"
                                                        placeholder={props.t("Enter Subdistrict")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sub_district || ""}
                                                        invalid={
                                                            validation.touched.sub_district &&
                                                                validation.errors.sub_district
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    {/* <Input
                                                        name="sub_district"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sub_district || ""}
                                                        invalid={
                                                            validation.touched.sub_district &&
                                                                validation.errors.sub_district
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Subdistrict")}</option>
                                                        {sub_district?.subDistrictData?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name_en}</option>
                                                        ))}
                                                    </Input> */}
                                                    {validation.touched.sub_district &&
                                                        validation.errors.sub_district ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.sub_district}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Address")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="address"
                                                        placeholder={props.t("Enter Address")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address &&
                                                                validation.errors.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address &&
                                                        validation.errors.address ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Moo")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="moo"
                                                        placeholder={props.t("Enter Moo")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.moo || ""}
                                                        invalid={
                                                            validation.touched.moo &&
                                                                validation.errors.moo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.moo &&
                                                        validation.errors.moo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.moo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Road")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="road"
                                                        placeholder={props.t("Enter Road")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.road || ""}
                                                        invalid={
                                                            validation.touched.road &&
                                                                validation.errors.road
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.road &&
                                                        validation.errors.road ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.road}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Village")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="village"
                                                        placeholder={props.t("Enter Village")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.village || ""}
                                                        invalid={
                                                            validation.touched.village &&
                                                                validation.errors.village
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.village &&
                                                        validation.errors.village ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.village}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Zip Code")}</Label>
                                                    <Input
                                                        disabled={true}
                                                        name="zip_code"
                                                        placeholder={props.t("Enter Zip Code")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.zip_code || ""}
                                                        invalid={
                                                            validation.touched.zip_code &&
                                                                validation.errors.zip_code
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.zip_code &&
                                                        validation.errors.zip_code ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.zip_code}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Latitude")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="latitude"
                                                        placeholder={props.t("Enter Latitude")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.latitude || ""}
                                                        invalid={
                                                            validation.touched.latitude &&
                                                                validation.errors.latitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.latitude &&
                                                        validation.errors.latitude ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.latitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Longitude")}
                                                    </Label>
                                                    <Input
                                                        disabled={true}
                                                        name="longitude"
                                                        placeholder={props.t("Enter Longitude")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.longitude || ""}
                                                        invalid={
                                                            validation.touched.longitude &&
                                                                validation.errors.longitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.longitude &&
                                                        validation.errors.longitude ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.longitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {/* <Button color="primary" type="submit">
                                            {props.t("Submit")}
                                        </Button> */}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(EditPharmacy))