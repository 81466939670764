import React, { useState, useEffect } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardTitle,
  Container,
} from "reactstrap";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import { PageContentAPI } from "../../apis/PageContentAPI";
import Swal from 'sweetalert2';
import { useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const EditPrivacy = (props) => {
  const history = useHistory();
  const page_content_id = useParams().page_content_id;
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [page_name, setPageName] = useState("");
  const [edit, setEdit] = useState("Edit");
  const [converted_content, setConvertedContent] = useState(null);

  useEffect(() => {
    getPageContent()
  }, [])

  const getPageContent = () => {
    PageContentAPI.fetchPageContent(page_content_id)
      .then(res => {
        setPageName(res.data.pageContent.page_name)
        setContent(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(res.data.pageContent.content)
          )
        ))

      }).catch(err => {
        console.log(err)
      })
  }

  const handleEditorContentChange = (state) => {
    setContent(state);
    convertContentTextToHTML();
  }
  const convertContentTextToHTML = () => {
    let currentContentTextAsHTML = convertToHTML(content.getCurrentContent());
    setConvertedContent(currentContentTextAsHTML);
  }



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      content: content,

    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {

      const data = new FormData();
      data.append('content', converted_content);
      data.append('page_content_id', page_content_id);
      PageContentAPI.updatePageContent(data)
        .then(res => {
          Swal.fire({
            text: "Page Content Updated Successfully",
            icon: 'success',
            imageAlt: 'success image',
            // confirmButtonColor: '#00CA84'
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/privacy-policy');
            }
          });
        }).catch(function (error) {
          // setLoader(false)
          Swal.fire({
            text: error?.response?.data?.message,
            icon: 'error',
            imageAlt: 'error image',
            // confirmButtonColor: '#00CA84'
          });
        });

    }
  });





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Forms")} breadcrumbItem={props.t(`${edit} ${page_name}`)} />
          <Row>
            <Col className="col-12">

              <Button style={{ float: "right", marginBottom: "20px", }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                Back
              </Button>

            </Col>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle>React-draft-wysiwyg</CardTitle> */}

                  <Form method="post" className="wysiwyg-custom" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Editor
                      name="content"
                      editorState={content}
                      onEditorStateChange={handleEditorContentChange}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                    />
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default (withTranslation()(EditPrivacy))