import React, { useEffect } from "react"
import { Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Token } from "../../Token";
import { AuthAPI } from "../../apis/AuthAPI";
import Swal from 'sweetalert2';
import googleplayicon from "../../assets/images/googleplaystore.png"
import applestoreicon from "../../assets/images/applestore.png"

// import images
// import logo from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"

const Login = (props) => {

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
      username: Yup.string().max(255).required('Email or license is required'),
      password: Yup.string().min(8).max(16).required('Password is required')
    }),
    onSubmit: (values) => {
      loginUser(values)
    }
  });

  useEffect(() => {
    document.body.className = "authentication-bg image-login-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const loginUser = (value) => {
    // setLoader(true)
    AuthAPI.login(value)
      .then(res => {
        // setLoader(false)
        Token.saveToken(res.data.user, res.data.token);
        if (res.data.user.role_id == 1) {
          window.location.href = '/admin';
        } 
        if (res.data.user.role_id == 2) {
          window.location.href = '/pharmacy';
        } 
        else{
          window.location.href = '/';
        }
        // Swal.fire({
        //   text: `Welcome back ${res.data.user.name_en}`,
        //   icon: 'success',
        //   imageAlt: 'success image',
        //   // confirmButtonColor: '#00CA84'
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     // if (res.data.user.role_id == 1) {
        //     //   window.location.href = '/admin';
        //     // } else {
        //     //   window.location.href = '/';
        //     // }
        //   }
        // });
      }).catch(function (error) {
        // setLoader(false)
        Swal.fire({
          text: error?.response?.data?.message,
          icon: 'error',
          imageAlt: 'error image',
          // confirmButtonColor: '#00CA84'
        });
      });
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Pharmacy Council</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email or License</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter email or license"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>


                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="ft-color">© {new Date().getFullYear()} Pharmacy Council.</p>
              </div>

              <Row lg={8} className="align-items-center justify-content-center mt-3">
              <Col sm={4}><a target="_blank" href="https://play.google.com/store/apps/details?id=com.pharmacist" title="Google Play Store"><img src={googleplayicon} height="40px" /></a></Col>
              <Col sm={4}><a target="_blank" href="https://apps.apple.com/us/app/my-pharmacist-thai/id6444803132" title="Apple Store"><img src={applestoreicon} height="40px" /></a></Col>
              </Row>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login;