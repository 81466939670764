import moment from 'moment';
export const dateFormat = (date) => {
    var date = new Date(date).toISOString().replace(/.000Z/, "");
    return moment(date).format("DD-MM-YYYY");
  };

export const dateTimeFormat = (datetime) => {
  return moment(datetime).format("DD-MM-YYYY");
};

export const TimeFormat = (datetime) => {
  return moment(datetime, "hh:mm").format('LT'); //moment(datetime).format("h:mm A UTC");
};
