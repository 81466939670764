import config from "../config";
import axios from "axios"
import { getToken } from "../Token";


export const IdCardInstructionAPI = {
    getAllIdCardInstruction: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-id-card-instructions`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
   
    createIdCardInstruction: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/create-id-card-instruction`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    
  
    getIdCardInstruction: async (id_card_instruction_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-id-card-instruction/${id_card_instruction_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
   

    updateIdCardInstruction: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-id-card-instruction`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
   
    deleteIdCardInstruction: async (id_card_instruction_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}/api/admin/delete-id-card-instruction/${id_card_instruction_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }

}