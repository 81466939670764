import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Form,
    Button,
  } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import { PharmacyListAPI } from "../../apis/PharmacyListAPI";
import { PharmacistsAPI } from "../../apis/PharmacistsAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"
import thailand from "../../assets/images/flags/download.jpeg"
import * as Yup from "yup";
import { useFormik } from "formik";

const PharmacyList = (props) => {
    const [pharmacies, setPharmacy] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: 10,
    });
    const [pharmacist, setPharmacist] = useState({ pharmacy_id: '', pharmacistdata: [] });

    useEffect(() => {
        getPharmacy(activeData);
        getAllPharmacists(pharmacist);
    }, []);

    const getAllPharmacists = (pharmacist) => {
        PharmacistsAPI.getAllPharmacistsList(pharmacist)
          .then((res) => {
            setPharmacist({ pharmacistdata: res.data.pharmacist.rows });
          })
          .catch((err) => {
            console.log(err);
          });
      };
    const columns = [
        {
            name: props.t("Sr.No"),
            selector: row => row.id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Photo"),
            selector: row => row.thumbnail,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Pharmacy Name"),
            selector: row => row.pharmacy_name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Mobile"),
            selector: row => row.mobile,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Pharmacy Type"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getPharmacy = (data) => {
        setLoading(true);
        PharmacyListAPI.getAllPharmacy(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.response.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res.data.response.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        // thumbnail: ++i,
                        id: sr_no,
                        thumbnail: (
                            <>
                                <img
                                    className=" header-profile-user"
                                    src={`${config.API_URL}/${v?.User?.user_image}`}

                                />
                            </>
                        ),
                        pharmacy_name: v.pharmacy_name,
                        mobile: v.mobile,
                        action: v.prarmacy_type,
                    };
                });
                setPharmacy(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getPharmacy(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getPharmacy(data)
    }

     // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pharmacist_id: '',
    },
    onSubmit: (values) => {
      activeData.activePage = 1;
      activeData.totalPage = 1;
      activeData.search = values;
      activeData.limit = 10;
      setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
      getPharmacy(activeData);

    }
  });

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("Pharmacy Report")} />

                    <Row>
                        {/* <Col className="col-12">
                            <Link to="/add-pharmacy">
                                <Button
                                    style={{ float: "right", marginBottom: "20px" }}
                                    color="primary"
                                    className="waves-effect waves-light  pull-right"
                                >
                                    {props.t("Add")}
                                </Button>
                            </Link>
                        </Col> */}

                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Pharmacist")}
                                                    </Label>
                                                    <Input
                                                        name="pharmacist_id"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacist_id || ""}
                                                    // onChangePage={(value) => handleChangePharmacist(value)}
                                                    >
                                                        <option value="">
                                                            {props.t("Select Pharmacist")}
                                                        </option>
                                                        {pharmacist?.pharmacistdata?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name_en}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2" className="mt-4" style={{ paddingTop: 5 }}>
                                                <Button color="primary"
                                                // onClick={handleClickCustomSearch()}
                                                >
                                                    {props.t("Search")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <DataTableExtensions
                                        columns={columns}
                                        data={pharmacies}
                                        print={false}
                                        export={false}
                                        filter={false}
                                    >
                                        <DataTable
                                            className="table-bordered"
                                            noDataComponent={props.t('There are no records to display')}
                                            progressPending={loading}
                                            // columns={columns}
                                            // data={roles}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable

                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(PharmacyList))