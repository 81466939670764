import config from "../config";
import axios from "axios"
import { getToken } from "../Token";


export const PharmacistsAPI = {
    getAllPharmacists: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacist`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        

        return await axios(axiosConfig);
    },
    getAllPharmacistsPharmacyOwner: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-pharmacist`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        

        return await axios(axiosConfig);
    },
    getAllPharmacistsList: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacist-list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getAllPharmacistsListOwner: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-pharmacist-list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }, 

}