export const Token = {
    saveToken: (user, token) => {
        setToken(token);
        setUserID(user.id);
        setRole(user.role.role);
        setStorePermitNo(user.store_permit_no);
        storeName(user.name_en);
        storeEmail(user.email);
        storeProfilePhoto(user.user_image);
    },
    getToken: () => {
        return getToken()
    },
    getStorePermitNo: () => {
        return getStorePermitNo()
    },
    getUserID: () => {
        return getUserID()
    },
    getRole: () => {
        return getRole()
    },
    getName: () => {
        return getName()
    },
    storeName: (name) => {
        return storeName(name);
    },
    getStoreProfilePhoto: () => {
        return getStoreProfilePhoto();
    },
    storeProfilePhoto: (user_image) => {
        return storeProfilePhoto(user_image)
    },
    getEmail: () => {
        return getEmail()
    },
    storeEmail: (email) => {
        return storeEmail(email);
    },
    // getProfilePhoto: () => {
    //     return getProfilePhoto()
    // },
    
    isLoggedIn: () => {
        if (getToken()) {
            return true;
        }
        return false;
    },
    clearStorage: () => {
        return localStorage.clear();
    }
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const getToken = () => {
    return localStorage.getItem('token');
}

export const getStorePermitNo = () => {
    return localStorage.getItem('store_permit_no');
}

export const isLoggedIn = () => {
    if (getToken()) {
        return true;
    }
    return false;
}

const setUserID = (user_id) => {
    localStorage.setItem('user_id', user_id);
}

export const getRole = () => {
    return localStorage.getItem('role');
}

export const setRole = (role) => {
    localStorage.setItem('role', role);
}
export const setStorePermitNo = (store_permit_no) => {
    localStorage.setItem('store_permit_no', store_permit_no);
}
export const getUserID = () => {
    return localStorage.getItem('user_id');
}
export const getStoreProfilePhoto = () => {
    return localStorage.getItem('user_image');
}

export const storeProfilePhoto = (user_image) => {
    localStorage.setItem('user_image', user_image);
}
// getStoreProfilePhoto

export const getName = () => {
    return localStorage.getItem('name');
}
export const storeName = (name) => {
    localStorage.setItem('name', name);
}

export const storeEmail = (email) => {
    localStorage.setItem('email', email);
}

export const getEmail = () => {
    return localStorage.getItem('email');
}

// export const storeProfilePhoto = (profile_photo) => {
//     localStorage.setItem('profile_photo', profile_photo ? profile_photo : '');
// }

// export const getProfilePhoto = () => {
//     return localStorage.getItem('profile_photo');
// }