import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { Token } from "../../../Token";
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom";
import config from "../../../config";

// users
import user4 from "../../../assets/images/users/avatar-2.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu" {`${config.API_URL}/${v?.User?.user_image}`}
  const [menu, setMenu] = useState(false);
  const username = Token.getName();
  const user_image = Token.getStoreProfilePhoto();
  // const user_profile = {config.API_URL}/{user_image};

  const logout = () => {
    Token.clearStorage()
    window.location.href = '/login';
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user_image ? `${config.API_URL}/${user_image}`: user4}
            alt="Header Avatar"
          /> */}
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/" className="dropdown-item">
            <div onClick={logout}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span >{props.t("Logout")}</span>
            </div>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
