import React, { useEffect, useState, useLayoutEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardSubtitle,
  Button,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import { RatingAPI } from "../../apis/RatingAPI";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI"

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";

import QRCode from 'qrcode.react';
import { getStorePermitNo } from "../../Token";
 
const QRCodeGenerate = (props) => {

  const [items, setItems] = useState([]);
  const history = useHistory();
  
  const storePermitNo = '"' + getStorePermitNo().replace(/"/g, '') + '"';

  useEffect(() => PharmacyListAPI.getPharmacyPharmacyOwner(localStorage.getItem('user_id'))
    .then(res => {
      setItems(localStorage.setItem('store_permit_no', JSON.stringify(res.data.user.store_permit_no)));
    }).catch(err => {
      console.log(err)
    }), []);
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${getStorePermitNo()}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Table")}
            breadcrumbItem={props.t("QR Code")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>

                  <Row>
                    <Col md="3"></Col>
                    <Col md="4">
                      <QRCode
                        id="qr-gen"
                        value={storePermitNo}
                        size={400}
                        level={"H"}
                        includeMargin={true}
                      />

                      <Button style={{ marginLeft: "120px", }}
                        color="primary"
                        onClick={downloadQRCode}
                      >
                        Download QR Code
                      </Button>
                    </Col>

                  </Row>





                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(QRCodeGenerate);
