import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI";
import { DistrictAPI } from "../../apis/DistrictAPI";
import { SubDistrictAPI } from "../../apis/SubDistrictAPI";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

const AddPharmacy = (props) => {
    const [files1, setFiles1] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locations, setLocation] = useState([]);
    const [district_id, setDistrictId] = useState([]);
    const [sub_district_id, setSubDistrictId] = useState([]);
    const [pharmacy_type, setPharmacyType] = useState([]);
    const profileDetail = <h1>{props.t("Profile Detail")}</h1>;
    const pharmacyDetail = <h1>{props.t("Pharmacy Detail")}</h1>;
    const [pharmacies, setPharmacies] = useState({ pharmaciydata: [], pharmaciyalldata: [] });
    const [district, setDistrict] = useState({ districtData: [], districtAllData: [] });
    const [sub_district, setSubDistrict] = useState({ subDistrictData: [], subDistrictAllData: [] });
    const [pharmacist, setPharmacist] = useState({ pharmacy_id: '', pharmacistdata: [] });
    const [provinces, setProvince] = useState([]);
    const [province_id, setProvinceId] = useState();

    const history = useHistory();

    useEffect(() => {
        getAllLocationAndUser();
    }, []);

    const getAllLocationAndUser = () => {
        setLoading(true);
        PharmacyListAPI.getAllLocationAndUser()
            .then((res) => {
                setProvince(res.data.response.rows)
                setPharmacyType(res.data.pharmacyType.rows)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChangeProvince = (province_id) => {

        validation.setFieldValue("province_id", province_id);
        setProvinceId(province_id)
        // if (province_id) {
        //     DistrictAPI.getAllDistrictByProvinces(province_id)
        //         .then((res) => {
        //             setDistrict({ districtData: res?.data?.district?.rows, districtAllData: res?.data?.district?.rows });
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // }
    };

    const handleChangeDistrict = (district_id) => {
        validation.setFieldValue("district_id", district_id);
        // if (province_id && district_id) {
        //     SubDistrictAPI.getAllSubDistrictByProvinceAndDistricts(district_id, province_id)
        //         .then((res) => {
        //             setSubDistrict({ subDistrictData: res?.data?.subDistrict?.rows, subDistrictAllData: res?.data?.subDdistrict?.rows });
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // }

    };

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            pharmacy_name: "",
            pharmacy_name_th: "",
            mobile: "",
            password: "",
            email: "",
            store_permit_no: "",
            license: "",
            website_url: "",
            permit_owner: "",
            line_id: "",
            prarmacy_type: "",
            province_id: "",
            district: "",
            sub_district: "",
            address: "",
            latitude: "",
            longitude: "",
            zip_code: "",
            contract_number: "",
            contract_number_2: "",
            moo: "",
            road: "",
            village: ""
        },
        validationSchema: Yup.object({
            pharmacy_name: Yup.string().max(40).required(props.t("Pharmacy Name is required")).matches(/^[aA-zZ\s]+$/, props.t("Only alphabets are allowed for this field ")),
            pharmacy_name_th: Yup.string().max(40).required(props.t("Pharmacy Name Thai is required")),
            email: Yup.string().email("Field should contain a valid e-mail").max(255).required(props.t("Email is required")),
            password: Yup.string().min(8).max(16).required(props.t("Password is required")),
            mobile: Yup.string().required(props.t("Mobile is required")).matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            contract_number: Yup.string().required(props.t("Contact Number is required")).matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            // user_id: Yup.string().required(props.t("User name is required")),
            website_url: Yup.string().matches(
                /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
                props.t('Please Enter Correct url!')
            ).required(props.t("Website Url is required")),
            // license: Yup.string().max(40).required(props.t("License Number is required")),
            permit_owner: Yup.string().max(40).required(props.t("Permit Owner is required")).matches(/^[aA-zZ\s]+$/, props.t("Only alphabets are allowed for this field ")),
            store_permit_no: Yup.string().max(40).required(props.t("store_permit_no is required")),
            latitude: Yup.string().required(props.t("Latitude is required")).matches(/^[+-]?\d+(\.\d+)?$/, props.t("Only Number are Allowed For This Field")),
            zip_code: Yup.string().max(5).required(props.t("Zip Code is required")).matches(/^[0-9\b]+$/, props.t("Only Number are Allowed For This Field")),
            longitude: Yup.string().required(props.t("Longitude is required")).matches(/^[+-]?\d+(\.\d+)?$/, props.t("Only Number are Allowed For This Field")),
            line_id: Yup.string().required(props.t("Line Id is required")),
            province_id: Yup.string().required(props.t("Province is required")),
            district: Yup.string().required(props.t("District is required")),
            // sub_district: Yup.string().required(props.t("Sub District is required")),
            prarmacy_type: Yup.string().required(props.t("Prarmacy Type is required")),
            address: Yup.string().required(props.t("Address is required")),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('user_image', files1);
            data.append('pharmacy_name', values.pharmacy_name);
            data.append('pharmacy_name_th', values.pharmacy_name_th);
            data.append('password', values.password);
            data.append('license', values.license);
            data.append('email', values.email);
            data.append('mobile', values.mobile);
            data.append('contract_number_2', values.contract_number_2);
            data.append('store_permit_no', values.store_permit_no);
            data.append('line_id', values.line_id);
            data.append('prarmacy_type', values.prarmacy_type);
            data.append('province_id', values.province_id);
            data.append('district', values.district);
            data.append('sub_district', values.sub_district);
            data.append('latitude', values.latitude);
            data.append('longitude', values.longitude);
            data.append('zip_code', values.zip_code);
            data.append('moo', values.moo);
            data.append('road', values.road);
            data.append('village', values.village);
            // data.append('permit_number', values.permit_number);
            data.append('permit_owner', values.permit_owner);
            data.append('contract_number', values.contract_number);
            data.append('website_url', values.website_url);
            data.append('address', values.address);
            PharmacyListAPI.createPharmacy(data)
                .then((res) => {
                    // setLoader(false)
                    if (res.data.message == "Invalid Record") {
                        Swal.fire({
                            text: props.t("Invalid Record"),
                            icon: "success",
                            imageAlt: "success image",
                            // confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // history.push("/pharmacies");
                            }
                        });
                    } else {
                        Swal.fire({
                            text: props.t("Pharmacy Saved Successfully"),
                            icon: "success",
                            imageAlt: "success image",
                            // confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push("/pharmacies");
                            }
                        });
                    }

                })
                .catch(function (error) {
                    // setLoader(false)
                    Swal.fire({
                        text: error?.response?.data?.message,
                        icon: "error",
                        imageAlt: "error image",
                        // confirmButtonColor: '#00CA84'
                    });
                });
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Forms")} breadcrumbItem={props.t("Add Pharmacy")} />
                    <Row>
                        <Col className="col-12">
                            <Button
                                style={{ float: "right", marginBottom: "20px" }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>
                        </Col>

                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row >
                                            {profileDetail}


                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Permit Owner")}</Label>
                                                    <Input
                                                        name="permit_owner"
                                                        placeholder={props.t("Enter Permit Owner")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.permit_owner || ""}
                                                        invalid={
                                                            validation.touched.permit_owner &&
                                                                validation.errors.permit_owner
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.permit_owner &&
                                                        validation.errors.permit_owner ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.permit_owner}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Password")}</Label>
                                                    <Input
                                                        name="password"
                                                        placeholder={props.t("Enter Password")}
                                                        type="password"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password &&
                                                                validation.errors.password
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.password &&
                                                        validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.password}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Email")}</Label>
                                                    <Input
                                                        name="email"
                                                        placeholder={props.t("Enter Email")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email &&
                                                                validation.errors.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                        validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("License")}</Label>
                                                    <Input
                                                        name="license"
                                                        placeholder={props.t("Enter License")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.license || ""}
                                                        invalid={
                                                            validation.touched.license &&
                                                                validation.errors.license
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.license &&
                                                        validation.errors.license ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.license}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col> */}
                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Mobile")}
                                                    </Label>
                                                    <Input
                                                        name="mobile"
                                                        placeholder={props.t("Enter Mobile Number")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mobile || ""}
                                                        invalid={
                                                            validation.touched.mobile &&
                                                                validation.errors.mobile
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.mobile &&
                                                        validation.errors.mobile ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.mobile}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Upload Pharmacy Image")}
                                                    </Label>
                                                    <Input
                                                        name="user_image"
                                                        placeholder={props.t("Enter Pharmacy Image Url")}
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFiles1(e.target.files[0]);
                                                            validation.setFieldValue('user_image', e.target.files[0]);
                                                        }}
                                                        invalid={
                                                            validation.touched.user_image &&
                                                                validation.errors.user_image
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.user_image &&
                                                        validation.errors.user_image ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.user_image}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {pharmacyDetail}
                                        <Row >

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Name")}</Label>
                                                    <Input
                                                        name="pharmacy_name"
                                                        placeholder={props.t("Enter Pharmacy Name")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacy_name || ""}
                                                        invalid={
                                                            validation.touched.pharmacy_name &&
                                                                validation.errors.pharmacy_name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.pharmacy_name &&
                                                        validation.errors.pharmacy_name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.pharmacy_name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Name Thai")}</Label>
                                                    <Input
                                                        name="pharmacy_name_th"
                                                        placeholder={props.t("Enter Pharmacy Name Thai")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.pharmacy_name_th || ""}
                                                        invalid={
                                                            validation.touched.pharmacy_name_th &&
                                                                validation.errors.pharmacy_name_th
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.pharmacy_name_th &&
                                                        validation.errors.pharmacy_name_th ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.pharmacy_name_th}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Pharmacy Type")}
                                                    </Label>
                                                    <Input
                                                        name="prarmacy_type"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.prarmacy_type || ""}
                                                        invalid={
                                                            validation.touched.prarmacy_type &&
                                                                validation.errors.prarmacy_type
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Pharmacy Type")}</option>
                                                        {pharmacy_type?.map((item) => (

                                                            <option key={item.id} value={item.name_en}>{item.name_en}</option>

                                                        ))}
                                                    </Input>
                                                    {validation.touched.prarmacy_type &&
                                                        validation.errors.prarmacy_type ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.prarmacy_type}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Store Permit Number")}
                                                    </Label>
                                                    <Input
                                                        name="store_permit_no"
                                                        placeholder={props.t("Enter Store Permit Number")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.store_permit_no || ""}
                                                        invalid={
                                                            validation.touched.store_permit_no &&
                                                                validation.errors.store_permit_no
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.store_permit_no &&
                                                        validation.errors.store_permit_no ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.store_permit_no}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Contact Number 1")}
                                                    </Label>
                                                    <Input
                                                        name="contract_number"
                                                        placeholder={props.t("Enter Contact  Number 1")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contract_number || ""}
                                                        invalid={
                                                            validation.touched.contract_number &&
                                                                validation.errors.contract_number
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.contract_number &&
                                                        validation.errors.contract_number ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contract_number}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Contact Number 2")}
                                                    </Label>
                                                    <Input
                                                        name="contract_number_2"
                                                        placeholder={props.t("Enter Contact Number 2")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contract_number_2 || ""}
                                                        invalid={
                                                            validation.touched.contract_number_2 &&
                                                                validation.errors.contract_number_2
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.contract_number_2 &&
                                                        validation.errors.contract_number_2 ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.contract_number_2}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>


                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Website Url")}</Label>
                                                    <Input
                                                        name="website_url"
                                                        placeholder={props.t("Enter Website Url")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.website_url || ""}
                                                        invalid={
                                                            validation.touched.website_url &&
                                                                validation.errors.website_url
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.website_url &&
                                                        validation.errors.website_url ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.website_url}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Line Id")}</Label>
                                                    <Input
                                                        name="line_id"
                                                        placeholder={props.t("Enter Line Id")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.line_id || ""}
                                                        invalid={
                                                            validation.touched.line_id &&
                                                                validation.errors.line_id
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.line_id &&
                                                        validation.errors.line_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.line_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>




                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Province")}
                                                    </Label>
                                                    <Input
                                                        name="province_id"
                                                        type="select"
                                                        className="form-control"
                                                        // onChange={validation.handleChange}
                                                        onChange={(e) => handleChangeProvince(e.target.value)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.province_id || ""}
                                                        invalid={
                                                            validation.touched.province_id &&
                                                                validation.errors.province_id
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Province")}</option>
                                                        {provinces?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name_en}</option>
                                                        ))}
                                                    </Input>
                                                    {validation.touched.province_id &&
                                                        validation.errors.province_id ? (
                                                        <div type="invalid" style={{ color: '#f46a6a' }}>
                                                            {validation.errors.province_id}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("District")}
                                                    </Label>
                                                    <Input
                                                        name="district"
                                                        placeholder={props.t("Enter District")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.district || ""}
                                                        invalid={
                                                            validation.touched.district &&
                                                                validation.errors.district
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {/* <select
                                                        name="district_id"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={(e) => handleChangeDistrict(e.target.value)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.district_id || ""}
                                                        invalid={
                                                            validation.touched.district_id &&
                                                                validation.errors.district_id
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select District")}</option>
                                                        {district?.districtData?.map((item) => (

                                                            <option key={item.id} value={item.id}>{item.name_en}</option>

                                                        ))}
                                                    </select> */}
                                                    {validation.touched.district &&
                                                        validation.errors.district ? (
                                                        <div type="invalid" style={{ color: '#f46a6a', fontSize: '20px' }}>
                                                            {validation.errors.district}
                                                        </div>

                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Subdistrict")}
                                                    </Label>
                                                    <Input
                                                        name="sub_district"
                                                        placeholder={props.t("Enter Subdistrict")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sub_district || ""}
                                                        invalid={
                                                            validation.touched.sub_district &&
                                                                validation.errors.sub_district
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {/* <Input
                                                        name="sub_district_id"
                                                        type="select"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sub_district_id || ""}
                                                        invalid={
                                                            validation.touched.sub_district_id &&
                                                                validation.errors.sub_district_id
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <option value="">{props.t("Please Select Subdistrict")}</option>
                                                        {sub_district?.subDistrictData?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name_en}</option>
                                                        ))}
                                                    </Input> */}
                                                    {validation.touched.sub_district &&
                                                        validation.errors.sub_district ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.sub_district}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Address")}</Label>
                                                    <Input
                                                        name="address"
                                                        placeholder={props.t("Enter Address")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address &&
                                                                validation.errors.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address &&
                                                        validation.errors.address ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Moo")}</Label>
                                                    <Input
                                                        name="moo"
                                                        placeholder={props.t("Enter Moo")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.moo || ""}
                                                        invalid={
                                                            validation.touched.moo &&
                                                                validation.errors.moo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.moo &&
                                                        validation.errors.moo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.moo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Road")}</Label>
                                                    <Input
                                                        name="road"
                                                        placeholder={props.t("Enter Road")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.road || ""}
                                                        invalid={
                                                            validation.touched.road &&
                                                                validation.errors.road
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.road &&
                                                        validation.errors.road ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.road}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Village")}</Label>
                                                    <Input
                                                        name="village"
                                                        placeholder={props.t("Enter Village")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.village || ""}
                                                        invalid={
                                                            validation.touched.village &&
                                                                validation.errors.village
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.village &&
                                                        validation.errors.village ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.village}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Zip Code")}</Label>
                                                    <Input
                                                        name="zip_code"
                                                        placeholder={props.t("Enter Zip Code")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.zip_code || ""}
                                                        invalid={
                                                            validation.touched.zip_code &&
                                                                validation.errors.zip_code
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.zip_code &&
                                                        validation.errors.zip_code ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.zip_code}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Latitude")}
                                                    </Label>
                                                    <Input
                                                        name="latitude"
                                                        placeholder={props.t("Enter Latitude")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.latitude || ""}
                                                        invalid={
                                                            validation.touched.latitude &&
                                                                validation.errors.latitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.latitude &&
                                                        validation.errors.latitude ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.latitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Longitude")}
                                                    </Label>
                                                    <Input
                                                        name="longitude"
                                                        placeholder={props.t("Enter Longitude")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.longitude || ""}
                                                        invalid={
                                                            validation.touched.longitude &&
                                                                validation.errors.longitude
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.longitude &&
                                                        validation.errors.longitude ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.longitude}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Button color="primary" type="submit">
                                            {props.t("Submit")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(AddPharmacy))