import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IdCardInstructionAPI } from "../../apis/IdCardInstructionAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
const EditIdCardInstruction = (props) => {
    const history = useHistory();
    const id_card_instruction_id = useParams().id_card_instruction_id;
    const [instruction_en, setInstructionEn] = useState('')
    const [instruction_th, setInstructionTh] = useState('')
    const [email, setEmail] = useState('')
    const [address_en, setAddressEn] = useState('')
    const [address_th, setAddressTh] = useState('')
    const [website, setWebsite] = useState('')

    useEffect(() => {
        getIdCardInstruction()
    }, [])
    const getIdCardInstruction = () => {
        IdCardInstructionAPI.getIdCardInstruction(id_card_instruction_id)
            .then(res => {
                setInstructionEn(res.data.idCardInstruction.instruction_en)
                setInstructionTh(res.data.idCardInstruction.instruction_th)
                setEmail(res.data.idCardInstruction.email)
                setAddressEn(res.data.idCardInstruction.address_en)
                setAddressTh(res.data.idCardInstruction.address_th)
                setWebsite(res.data.idCardInstruction.website)
                
            }).catch(err => {
                console.log(err)
            })
    }
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            instruction_en: instruction_en,
            instruction_th: instruction_th,
            email: email,
            address_en: address_en,
            address_th: address_th,
            website: website,
        },
        validationSchema: Yup.object({

            address_en: Yup.string().required(props.t("Address is required")),
            instruction_th: Yup.string().required(props.t("Instruction (Thai) is required")),
            email: Yup.string().required(props.t("Email is required")),
            website: Yup.string().matches(
                /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
                props.t('Please Enter Correct url!')
            ).required(props.t("Website Url is required")),
            address_th: Yup.string().required(props.t("Address (Thai) Number is required")),
            instruction_en: Yup.string().required(props.t("Instruction (Eng) is required")),

        }),
        onSubmit: (values) => {
            values.id_card_instruction_id = id_card_instruction_id;
            IdCardInstructionAPI.updateIdCardInstruction(values)
                .then((res) => {
                    // setLoader(false)
                    Swal.fire({
                        text: props.t("Id Card Instruction Update Successfully"),
                        icon: "success",
                        imageAlt: "success image",
                        // confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/id-card-instructions");
                        }
                    });

                })
                .catch(function (error) {
                    // setLoader(false)
                    Swal.fire({
                        text: error?.response?.data?.message,
                        icon: "error",
                        imageAlt: "error image",
                        // confirmButtonColor: '#00CA84'
                    });
                });
        },
    });
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Forms")} breadcrumbItem={props.t("Edit Id Card Instruction")} />
                    <Row>
                        <Col className="col-12">
                            <Button
                                style={{ float: "right", marginBottom: "20px" }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>
                        </Col>

                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row >


                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Instruction (Eng)")}</Label>
                                                    <Input
                                                        name="instruction_en"
                                                        placeholder={props.t("Enter Instruction (Eng)")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.instruction_en || ""}
                                                        invalid={
                                                            validation.touched.instruction_en &&
                                                                validation.errors.instruction_en
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.instruction_en &&
                                                        validation.errors.instruction_en ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.instruction_en}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Instruction (Thai)")}</Label>
                                                    <Input
                                                        name="instruction_th"
                                                        placeholder={props.t("Enter Instruction (Thai)")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.instruction_th || ""}
                                                        invalid={
                                                            validation.touched.instruction_th &&
                                                                validation.errors.instruction_th
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.instruction_th &&
                                                        validation.errors.instruction_th ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.instruction_th}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Address (Eng)")}</Label>
                                                    <Input
                                                        name="address_en"
                                                        placeholder={props.t("Enter Address (Eng)")}
                                                        type="textarea"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address_en || ""}
                                                        invalid={
                                                            validation.touched.address_en &&
                                                                validation.errors.address_en
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address_en &&
                                                        validation.errors.address_en ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.address_en}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Address (Thai)")}</Label>
                                                    <Input
                                                        name="address_th"
                                                        placeholder={props.t("Enter Address (Thai)")}
                                                        type="textarea"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address_th || ""}
                                                        invalid={
                                                            validation.touched.address_th &&
                                                                validation.errors.address_th
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.address_th &&
                                                        validation.errors.address_th ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.address_th}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Email")}
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        placeholder={props.t("Enter Email")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email &&
                                                                validation.errors.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                        validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Website Url")}</Label>
                                                    <Input
                                                        name="website"
                                                        placeholder={props.t("Enter Website Url")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.website || ""}
                                                        invalid={
                                                            validation.touched.website &&
                                                                validation.errors.website
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.website &&
                                                        validation.errors.website ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.website}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Button color="primary" type="submit">
                                            {props.t("Submit form")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default (withTranslation()(EditIdCardInstruction))