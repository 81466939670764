import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { Link } from "react-router-dom";
import { CheckinCheckoutAPI } from "../../apis/CheckinCheckoutAPI";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { withTranslation } from "react-i18next";
import dateFormat from "dateformat";

const ChechinCheckout = (props) => {
  const [users, setUsers] = useState([]);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: 10,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers(activeData);
  }, []);

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.srno,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Name"),
      selector: row => row.pharmacist,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Pharmacy"),
      selector: row => row.pharmacy,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Date"),
      selector: (row) => row.date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Checkin"),
      selector: (row) => row.checkin_time,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Checkout"),
      selector: (row) => row.checkout_time,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ];

  const getUsers = (data) => {
    setLoading(true);
    CheckinCheckoutAPI.getAllCheckins(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.totalCounts,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        res.data.response.forEach((v, i) => {
          // console.log(dateFormat(v.checkin_time, "d-m-yyyy"));
          //console.log("error", res);
          data[i] = {
            // srno: ++i,
            srno: v.id,
            pharmacist:v.Pharmacist.User.name_en,
            pharmacy:v.Pharmacist.Pharmacy.pharmacy_name,
            date: dateFormat(v.created_at, "d-m-yyyy"),
            checkin_time: dateFormat(v.checkin_time, "HH:mm:ss"),
            checkout_time: dateFormat(v.checkout_time, "HH:mm:ss"),
            // mobile: v.mobile,
            action: (
              <>
                <Link to={`/user-profile/${v.id}`}>
                  {" "}
                  <i className="uil-eye font-size-20"></i>
                </Link>
              </>
            ),
          };
        });
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getUsers(data);
  };

  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getUsers(data);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Tables")}
            breadcrumbItem={props.t("Check-in/Check-out Report")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <DataTableExtensions
                    columns={columns}
                    data={users}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      // columns={columns}
                      // data={roles}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(ChechinCheckout);
