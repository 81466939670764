import enFlag from "../assets/images/flags/en.jpg"
import thailand from "../assets/images/flags/thailand.png"

const languages = {
  // sp: {
  //   label: "Spanish",
  //   flag: spain,
  // },
  // gr: {
  //   label: "German",
  //   flag: germany,
  // },
  // it: {
  //   label: "Italian",
  //   flag: italy,
  // },
  // rs: {
  //   label: "Russian",
  //   flag: russia,
  // },
  en: {
    label: "English",
    flag: enFlag,
  },
  thai: {
    label: "ไทย",
    flag: thailand,
  },
}

export default languages
