module.exports = {
  // API_URL: "http://localhost:8001",
  API_URL: "https://api.mypharmacistthailand.org",
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}