import config from "../config";
import axios from "axios"
import { getToken } from "../Token";


export const PageContentAPI = {
    getPageCntent: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-page-content`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    fetchPageContent: async (page_content_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-page-content/${page_content_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        return await axios(axiosConfig);
    },
    updatePageContent: async (data) => {
        console.log('data',data)
        const token = getToken();
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-page-content`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
}