import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
   Container,
   CardSubtitle,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import { RatingAPI } from "../../apis/RatingAPI";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";

const PharmacyOwnerRating = (props) => {
  const history = useHistory();

  const [rating_and_review, setRatingAndReview] = useState([]);
  const [loading, setLoading] = useState(false);
 
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: 10,
  });

  useEffect(() => {
    getPharmacyOwnerRating(activeData);
  }, []);

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Customer Name"),
      selector: (row) => row.customer_name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Review"),
      selector: (row) => row.comment,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Rating"),
      selector: (row) => row.rating,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ];

  const getPharmacyOwnerRating = (data) => {
    setLoading(true);
    RatingAPI.getPharmacyOwnerRatingAndReview(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.ratingAndReviews.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.ratingAndReviews.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          data[i] = {
            // srno: ++i,
            id: sr_no,
            customer_name:v.customer.name_en,
            comment: v.comment ? v.comment : "--",
            rating: v.rating,
            
          };
        });
        setRatingAndReview(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

 

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getPharmacyOwnerRating(data);
  };

  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getPharmacyOwnerRating(data);
  };

 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Table")}
            breadcrumbItem={props.t("Rating And Review List")}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    {props.t("Rating And Review List")}
                  </CardSubtitle>

                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <DataTableExtensions
                    columns={columns}
                    data={rating_and_review}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(PharmacyOwnerRating);
