import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardSubtitle,
} from "reactstrap";
import Swal from "sweetalert2";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
// import { PharmacyTypeAPI } from "../../apis/PharmacyTypeAPI";
import { BannerAPI } from "../../apis/BannerAPI";
import config from "../../config";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";

const PharmacyOwnerBannerList = (props) => {
  const history = useHistory();

  const [banner_list, setBannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name_en, setNameEn] = useState("");
  const [name_th, setNameTh] = useState("");
  const [sequence, setSequence] = useState("");
  const [banner_id, setBanner] = useState("");
  const [files, setFiles] = useState([]);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: 10,
  });

  useEffect(() => {
    getBannerList(activeData);
  }, []);

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Seq No."),
      selector: (row) => row.sequence,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Photo"),
      selector: (row) => row.image,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Banner Name (Eng)"),
      selector: (row) => row.name_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    

    {
      name: props.t("Action"),
      selector: (row) => row.action,
      sort: "asc",
      width: 200,
    },
  ];

  const getBannerList = (data) => {
    setLoading(true);
    BannerAPI.getBannerListPharmacyOwner(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.banner.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.banner.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          data[i] = {
            // srno: ++i,
            id: sr_no, 
            sequence: v.sequence,
            image: (
              <>
                <img
                  className=" header-profile-user"
                  src={`${config.API_URL}/${v.image}`}
                // src={thailand}
                />
              </>
            ),
            name_en: v.name_en,
            action: (
              <>
                <i
                  style={{ marginRight: "15px", color: "#7b8190" }}
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => onEdit(v.id)}
                />
                <i
                  className="mdi mdi-delete font-size-18"
                  style={{ color: "#7b8190" }}
                  id="deletetooltip"
                  onClick={() => onDelete(v.id)}
                />
              </>
            ),
          };
        });
        setBannerList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: props.t("Are you sure?"),
      text: props.t("You won't be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        BannerAPI.deleteBannerPharmacyOwner(id)
          .then((res) => {
            getBannerList(activeData);
            window.location.reload(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const onEdit = (banner_id) => {
    setBanner(banner_id);
    BannerAPI.getBannerPharmacyOwner(banner_id)
      .then((res) => {
        console.log(res);
        setNameEn(res.data.banner.name_en);
        setNameTh(res.data.banner.name_th);
        setSequence(res.data.banner.sequence);
        // setFiles(res.data.banner.image);
        getBannerList(activeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getBannerList(data);
  };

  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getBannerList(data);
  };

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name_en: name_en ? name_en : "",
      name_th: name_th ? name_th : "",
      sequence: sequence ? sequence : "",
      banner_image: "",
      // files: files ? files : "",
    },
    validationSchema: Yup.object({
      // name_en: Yup.string()
      //   .required(props.t("Please Enter Your Pharmacy Type  Name (Eng)")).matches(
      //     /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i,
      //     props.t("Only alphabets and special charactor are allowed for this field ")
      //   ),
      name_en: Yup.string()
      .required(props.t("Please Enter Your Pharmacy Type  Name (Eng)")),
      name_th:  Yup.string()
        .required(props.t("Please Enter Your Pharmacy Type  Name (Thai)")),
      
      banner_image: (banner_id) ? Yup.string() : Yup.string().required(props.t( "Please upload banner")),
     
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("banner_image", files);
      data.append("name_en", values.name_en);
      data.append("name_th", values.name_th);
      // data.append('on_duty_user_id', values.user_id);
      data.append("sequence", values.sequence);
      if (banner_id) {
        data.append("banner_id", banner_id);
        BannerAPI.updateBannerPharmacyOwner(data)
          .then((res) => {
            // setLoader(false)
            Swal.fire({
              text: props.t("Banner Updated Successfully"),
              icon: "success",
              imageAlt: "success image",
              // confirmButtonColor: '#00CA84'
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/banner_list");
                getBannerList(activeData);
                window.location.reload(false);
              }
            });
          })
          .catch(function (error) {
            // setLoader(false)
            Swal.fire({
              text: error.response.data.message,
              icon: "error",
              imageAlt: "error image",
              // confirmButtonColor: '#00CA84'
            });
          });
      } else {
        BannerAPI.createBannerPharmacyOwner(data)
          .then((res) => {
            // setLoader(false)
            Swal.fire({
              text: props.t("Banner Saved Successfully"),
              icon: "success",
              imageAlt: "success image",
              // confirmButtonColor: '#00CA84'
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/banner_list");
                getBannerList(activeData);
                window.location.reload(false);
              }
            });
          })
          .catch(function (error) {
            // setLoader(false)
            Swal.fire({
              text: error.response.data.message,
              icon: "error",
              imageAlt: "error image",
              // confirmButtonColor: '#00CA84'
            });
          });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Banner List")}
          />
          <Row>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-3">
                    {props.t("Banner List")}
                  </CardSubtitle>

                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <DataTableExtensions
                    columns={columns}
                    data={banner_list}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Banner Name (Eng)")}
                          </Label>
                          <Input
                            name="name_en"
                            // placeholder={props.t("Banner Name (Eng)")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name_en || ""}
                            invalid={
                              validation.touched.name_en &&
                                validation.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name_en &&
                            validation.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Banner Name (Thai)")}
                          </Label>
                          <Input
                            name="name_th"
                            // placeholder={props.t("Pharmacy Type Name (Thai)")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name_th || ""}
                            invalid={
                              validation.touched.name_th &&
                                validation.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name_th &&
                            validation.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Upload Banner")}
                          </Label>
                          <Input
                            name="banner_image"
                            placeholder={props.t("Enter Banner Image Url")}
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              setFiles(e.target.files[0]);
                              validation.setFieldValue('banner_image', e.target.files[0]);
                              // setFiles(e.target.files[0]);
                              // validation.setFieldValue(
                              //   "banner_image",
                              //   e.target.files[0]
                              // );
                            }}
                            invalid={
                              validation.touched.banner_image &&
                                validation.errors.banner_image
                                ? true
                                : false
                            }
                          />
                          {validation.touched.banner_image &&
                            validation.errors.banner_image ? (
                            <FormFeedback type="invalid">
                              {validation.errors.banner_image}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Sequence Number")}
                          </Label>
                          
                          <Input
                              name="sequence"
                              type="select"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.sequence || ""}
                              invalid={
                                  validation.touched.sequence &&
                                      validation.errors.sequence
                                      ? true
                                      : false
                              }
                          >
                              <option value="">{props.t("Please Select Sequence")}</option>
                              {[...Array(5)]?.map((elementInArray, index) => (

                                  <option key={index+1} value={index+1}>{index+1}</option>

                              ))}
                          </Input>


                          {/* <Input
                            name="sequence"  
                            // placeholder={props.t("Pharmacy Type Name (Thai)")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sequence || ""}
                            invalid={
                              validation.touched.sequence &&
                                validation.errors.sequence
                                ? true
                                : false
                            }
                          /> */}
                          {validation.touched.sequence &&
                            validation.errors.sequence ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sequence}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {name_en && (
                      <Button color="primary" type="submit">
                        {props.t("Update")}
                      </Button>
                    )}
                    {!name_en && (
                      <Button color="primary" type="submit">
                        {props.t("Add")}
                      </Button>
                    )}
                    {"  "}
                    <Button
                      color="light"
                      onClick={() => window.location.reload(false)}
                    >
                      {props.t("Cancel")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(PharmacyOwnerBannerList);
