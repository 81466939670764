import React, { useEffect, useState, useRef } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input, Container, FormFeedback, Form } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import config from "../../config";
import { ComplaintAPI } from "../../apis/ComplaintAPI";

import { useHistory, useParams } from "react-router-dom"
import { withTranslation } from "react-i18next";
import dateFormat from "dateformat";
import video_file_document from "../../assets/images/logo-sm1.png";

const EditPharmacy = (props) => {
    const history = useHistory();
    const elementRef = useRef(null);
    const complaint_id = useParams().complaint_id;
    const [pharmacy_name, setPharmacyName] = useState('')
    const [file_type, setFileType] = useState({})
    const [video_file, setVideoFile] = useState({
        src: video_file_document,
        poster: "/1.png"
    })

    const [comment, setComment] = useState('')
    const [name_en, setNameEn] = useState('')
    const [complaint_image, setComplaintImage] = useState('')
    const [created_at, setCreatedAt] = useState()



    const complaintDetail = <h3>{props.t("Complaint Profile")}</h3>;
    useEffect(() => {
        fetchComplaint()
    }, [])


    const fetchComplaint = () => {
        ComplaintAPI.fetchComplaint(complaint_id)
            .then(res => {
                setPharmacyName(res.data.complaint.pharmacy.pharmacy_details.pharmacy_name)
                setNameEn(res.data.complaint.customer.name_en)
                setComment(res.data.complaint.comment)
                setComplaintImage(res.data.complaint.complaint_image)
                setFileType(res.data.complaint.file_type)
                setCreatedAt(dateFormat(res.data.complaint.created_at, "d-m-yyyy"))
            }).catch(err => {
                console.log(err)
            })
    }



    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            pharmacy_name: pharmacy_name,
            name_en: name_en,
            comment: comment,
            complaint_image: complaint_image,
            created_at: created_at,

        },


    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Detail")} breadcrumbItem={props.t("Complaint Profile")} />
                    <Row>
                        <Col className="col-12">

                            <Button style={{ float: "right", marginBottom: "20px", }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>

                        </Col>

                        <Col xl="12" >
                            <Card>
                                <CardBody>
                                    <Row style={{ marginBottom: "20px", }}>
                                        {complaintDetail}
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">{props.t("Pharmacy Name")}</Label>
                                                <Input
                                                    name="pharmacy_name"
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.pharmacy_name || ""}
                                                    invalid={
                                                        validation.touched.pharmacy_name &&
                                                            validation.errors.pharmacy_name
                                                            ? true
                                                            : false
                                                    }
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">{props.t("Customer Name")}</Label>
                                                <Input
                                                    name="name_en"
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.name_en || ""}
                                                    invalid={
                                                        validation.touched.name_en &&
                                                            validation.errors.name_en
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">{props.t("Complaint")}</Label>
                                                <Input
                                                    name="comment"
                                                    type="textarea"
                                                    readOnly={true}
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.comment || ""}
                                                    invalid={
                                                        validation.touched.comment &&
                                                            validation.errors.comment
                                                            ? true
                                                            : false
                                                    }
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                    {props.t("Date")}
                                                </Label>
                                                <Input
                                                    name="created_at"
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.created_at || ""}
                                                    invalid={
                                                        validation.touched.created_at &&
                                                            validation.errors.created_at
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">{props.t("File")}</Label><br></br>

                                                {file_type === "IMAGE" && (
                                                    <img
                                                        className="form-control"
                                                        src={`${complaint_image}`}
                                                        style={{ width: '468px', height: '300px' }}
                                                    />

                                                )}

                                                {file_type === "VIDEO" && (
                                                    <video controls src={complaint_image} poster={video_file.poster} width="435" ref={elementRef} />
                                                )}


                                            </FormGroup>
                                        </Col>


                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>


                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(EditPharmacy))