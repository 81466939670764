import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const BusinessHourAPI = {
    getAllBusinessHour: async (pharmacy_id) => {
        // console.log('pharmacy_idpharmacy_id',pharmacy_id)
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-business-hour/${pharmacy_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            // data: data
        };

        return await axios(axiosConfig);
    },
    updateBusinessHour: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/pharmacy/update-business-hour`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },

}