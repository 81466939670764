import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const DashBoardAPI = {
    getCount: async () => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/get-count/`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getCountPharmacyOwner: async () => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/pharmacy/get-count/`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }

}
