import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { Link } from "react-router-dom";
import { CheckinCheckoutAPI } from "../../apis/CheckinCheckoutAPI";
import { PharmacistsAPI } from "../../apis/PharmacistsAPI";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import dateFormat from "dateformat";
import * as Yup from "yup";
import { useFormik } from "formik";
import pharmacist_image from "../../assets/images/flags/pharmacist image.jpg";
import Swal from "sweetalert2"

const PharmacyOwnerChechinCheckout = (props) => {
  const [users, setUsers] = useState([]);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: 10,
  });
  const [loading, setLoading] = useState(false);
  const [pharmacist, setPharmacist] = useState([]);
  // const [customsearch, setCustomsearch] = useState({
  //   pharmacy: "",
  //   pharmacist : "",
  //   checkin_date: "",
  //   checkout_date: ""
  // });

  useEffect(() => {
    getUsers(activeData);
    getAllPharmacists();
  }, []);



  const getAllPharmacists = () => {
    setLoading(true);
    PharmacistsAPI.getAllPharmacistsListOwner()
      .then((res) => {
        //console.log(res.data.pharmacist);
        setPharmacist(res.data.pharmacist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dateFormat = (date) => {
    var d = date.replace('.000Z', '');
    return moment(d).format("DD/MM/YYYY");
    // moment(yourDate).format("YYYY/MM/DD kk:mm:ss")
    // return moment(date).format("DD/MM/YYYY");


  };
  const timeFormat = (time) => {
    //moment(time).format("YYYY/MM/DD kk:mm:ss")
    var t = time.replace('.000Z', '')
    return moment(t).format("hh:mm A");
  };

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.srno,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Pharmacist"),
      selector: (row) => row.pharmacist,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Photo"),
      selector: row => row.img_url,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Date"),
      selector: (row) => row.date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Time"),
      selector: (row) => row.checkin_time,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type"),
      selector: (row) => row.checkout_time,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ];

  const getUsers = (data) => {
    setLoading(true);
    CheckinCheckoutAPI.getAllCheckinsPharmacyOwner(data)
      .then((res) => {

        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.response.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        if (res?.data?.response?.rows) {
          res?.data?.response?.rows.forEach((v, i) => {
            sr_no = sr_no + 1;
            // console.log(dateFormat(v.checkin_time, "d-m-yyyy"));
            data[i] = {
              // srno: ++i,
              srno: sr_no,
              pharmacist: v.pharmacist?.name_en,
              img_url: (
                <>
                  {
                    v.img_url ?
                      <a href="#!"
                        onClick={() => viewPhoto(v.img_url)}
                      >
                        <img
                          className=" header-profile-user"
                          src={v.img_url}
                        />
                      </a> :
                      <a href="#!"
                        onClick={() => viewPhoto(pharmacist_image)}
                      >
                        <img
                          className=" header-profile-user"
                          src={pharmacist_image}
                        />
                      </a>

                  }
                </>
              ),
              // pharmacy: v.User.Pharmacies.pharmacy_name,
              date: dateFormat(v.date_time),
              checkin_time: timeFormat(v.date_time),
              checkout_time: v.type,
              // mobile: v.mobile,
              action: (
                <>
                  <Link to={`/user-profile/${v.id}`}>
                    {" "}
                    <i className="uil-eye font-size-20"></i>
                  </Link>
                </>
              ),
            };
          });
        }


        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getUsers(data);
    console.log("1");
  };

  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getUsers(data);
  };


  const viewPhoto = async (imgPath) => {
    Swal.fire({
      imageUrl: imgPath,
      imageHeight: 400,
      imageWidth: 400,
      imageAlt: 'image'
    })
  };

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pharmacist_id: '',
      start_date: '',
      end_date: '',
    },
    validationSchema: Yup.object({
      start_date: Yup.date(),
      end_date: Yup.date().min(Yup.ref('start_date'), 'End date should be a greater than start date'),

    }),
    onSubmit: (values) => {
      activeData.activePage = 1;
      activeData.totalPage = 1;
      activeData.search = values;
      activeData.limit = 10;
      setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
      getUsers(activeData);

    }
  });


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Tables")}
            breadcrumbItem={props.t("Check-in/Check-out List")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      {/* <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Pharmacy")}
                          </Label>
                          <Input
                            name="pharmacy"
                            type="select"
                            className="form-control"
                            // onChangePage={(value) => handleChangePharmacy(value)}
                          >
                            <option value="">
                              {props.t("Select Pharmacy")}
                            </option>
                            {pharmacies.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.pharmacy_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col> */}
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Pharmacist")}
                          </Label>
                          <Input
                            name="pharmacist_id"
                            type="select"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pharmacist_id || ""}
                          // onChangePage={(value) => handleChangePharmacist(value)}
                          >
                            <option value="">
                              {props.t("Select Pharmacist")}
                            </option>
                            {pharmacist.map((item) => (
                              <option key={item.pharmacist_id} value={item.pharmacist_id}>
                                {item.pharmacist.name_en}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Start Date")}
                          </Label>
                          <Input
                            name="start_date"
                            placeholder={props.t("Enter News And Events Date")}
                            type="date"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.start_date || ""}
                          // onChangePage={(value) => handleChangeStartdate(value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("End Date")}
                          </Label>
                          <Input
                            name="end_date"
                            placeholder={props.t("Enter News And Events Date")}
                            type="date"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.end_date || ""}
                            invalid={
                              validation.touched.end_date &&
                                validation.errors.end_date
                                ? true
                                : false
                            }
                          // onChangePage={(value) => handleChangeEnddate(value)}
                          />
                        </FormGroup>
                        <div style={{ color: 'red' }}>{validation.errors.end_date}</div>

                      </Col>
                      <Col md="3" className="mt-4" style={{ paddingTop: 5 }}>
                        <Button color="primary"
                        // onClick={handleClickCustomSearch()}
                        >
                          {props.t("Search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <DataTableExtensions
                    columns={columns}
                    data={users}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      progressPending={loading}
                      noDataComponent={props.t('There are no records to display')}
                      // columns={columns}
                      // data={roles}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(PharmacyOwnerChechinCheckout);
