import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { UserAPI } from "../../apis/UserAPI"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { withTranslation } from "react-i18next";

const UserList = (props) => {

  const [users, setUsers] = useState([]);
  const [activeData, setActiveData] = useState({ activePage: 1, totalPage: 1, search: '', limit: 10 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers(activeData)
  }, [])

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: row => row.srno,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Name"),
      selector: row => row.name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Mobile"),
      selector: row => row.mobile,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Email"),
      selector: row => row.email,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },

  ];

  const getUsers = (data) => {
    setLoading(true);
    UserAPI.getAllUsers(data)
      .then(res => {
        setActiveData({ activePage: activeData.activePage, totalPage: res.data.users.count, search: activeData.search, limit: activeData.limit });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.users.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          data[i] = {
            // srno: ++i,
            srno: sr_no,
            name: v.name_en,
            email: v.email ? v.email : "--",
            mobile: v.mobile ? v.mobile : "--",
          }
        });
        setUsers(data)
        setLoading(false);
      }).catch(err => {
        console.log(err)
      })
  }


  const handleChange = (v) => {
    setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
    const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
    getUsers(data)
  }


  const handleRowChange = (v) => {
    setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
    const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
    getUsers(data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("Customer List")} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <DataTableExtensions
                    columns={columns}
                    data={users}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={value => handleRowChange(value)}
                      onChangePage={value => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default (withTranslation()(UserList))