import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PharmacistsAPI } from "../../apis/PharmacistsAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"
import pharmacist_image from "../../assets/images/flags/pharmacist image.jpg";

const PharmacyOwnerPharmacistsList = (props) => {
    const [pharmacists, setPharmacists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: 10,
    });

    useEffect(() => {
        getPharmacists(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Photo"),
            selector: row => row.thumbnail,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Pharmacist Name"),
            selector: row => row.pharmacy_name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Email"),
            selector: row => row.email,
            sort: "asc",
            width: 200,
        },
        {
            name: props.t("Mobile"),
            selector: row => row.mobile,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        }
    ];

    const getPharmacists = (data) => {
        setLoading(true);
        PharmacistsAPI.getAllPharmacistsPharmacyOwner(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.totalCounts,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                res?.data?.pharmacists?.rows.forEach((v, i) => {
                    data[i] = {
                        // thumbnail: ++i,
                        thumbnail: (
                            <>
                                <img
                                    className=" header-profile-user"
                                    // src={`${config.API_URL}/${v.pharmacy_image}`}thailand
                                    src={pharmacist_image}

                                />
                            </>
                        ),
                        pharmacy_name: v.name_en,
                        email: v.email,
                        mobile: v.mobile,
                    };
                });
                setPharmacists(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getPharmacists(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getPharmacists(data)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={props.t("Tables")} breadcrumbItem={props.t("Pharmacist List")} />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <DataTableExtensions
                                    
                                        columns={columns}
                                        data={pharmacists}
                                        print={false}
                                    export={false}
                                    filter={false}
                                    >
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            noDataComponent={props.t('There are no records to display')}
                                            // columns={columns}
                                            // data={roles}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable

                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(PharmacyOwnerPharmacistsList))