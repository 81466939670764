import config from "../config";
import axios from "axios"
import { getToken } from "../Token";


export const PharmacyListAPI = {
    getAllPharmacy: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacies`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getAllPharmacyRecord: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacy-record`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getAllPharmacies: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-pharmacies-list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createPharmacy: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/create-pharmacy`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },

    getAllLocationAndUser: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-location-and-user`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getPharmacy: async (pharmacy_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-pharmacy/${pharmacy_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getPharmacyPharmacyOwner: async (pharmacy_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/pharmacy/fetch-pharmacy/${pharmacy_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    // getPharmacyPharmacyOwner: async (pharmacy_id) => {
    //     const token = getToken();
    //     var axiosConfig = {
    //         method: 'get',
    //         url: `${config.API_URL}/api/pharmacy/fetch-pharmacy/${pharmacy_id}`,
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-Type': 'application/json'
    //         }
    //     };

    //     return await axios(axiosConfig);
    // },
    updatePharmacy: async (data) => {
        const token = getToken();
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-pharmacy`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updatePharmacyPharmacyOwner: async (data) => { 
        const token = getToken();
        var axiosConfig = {
            method: 'put', 
            url: `${config.API_URL}/api/pharmacy/update-pharmacy`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updatePharmacyPharmacyOwnerDetails: async (data) => { 
        const token = getToken();
        var axiosConfig = {
            method: 'post', 
            url: `${config.API_URL}/api/pharmacy/update-pharmacy-details`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deletePharmacy: async (pharmacy_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}/api/admin/delete-pharmacy/${pharmacy_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getAPIAllPharmacies: async () => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/get-api-acc-all-pharmacies`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getAPIPharmaciesWithDate: async (date) => {
        const token = getToken();
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/get-api-acc-pharmacies-with-date`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: { date }
        };

        return await axios(axiosConfig);
    },
    sendEmailForPassword: async (user_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/pharmacy/sendEmailForPassword/${user_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }
}