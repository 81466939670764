import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  FormFeedback,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import { Link } from "react-router-dom";
import { CheckinCheckoutAPI } from "../../apis/CheckinCheckoutAPI";
import { ProvinceAPI } from "../../apis/ProvinceAPI";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI";
import { PharmacistsAPI } from "../../apis/PharmacistsAPI";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import helper from "../../helpers/fakebackend_helper"
import dateFormat from "dateformat";
import * as Yup from "yup";
import { useFormik } from "formik";
import pharmacist_image from "../../assets/images/flags/pharmacist image.jpg";
import Swal from "sweetalert2"

const ChechinCheckout = (props) => {
  const [users, setUsers] = useState([]);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: {
      province_id: '',
      pharmacy_id: '',
      pharmacist_id: '',
      start_date: '',
      end_date: '',
    },
    limit: 10,

  });
  const [loading, setLoading] = useState(false);
  const [pharmacies, setPharmacies] = useState({ pharmaciydata: [], pharmaciyalldata: [] });
  const [pharmacist, setPharmacist] = useState({ pharmacy_id: '', pharmacistdata: [] });
  const [provinces, setProvince] = useState([]);
  // const [customsearch, setCustomsearch] = useState({
  //   pharmacy: "",
  //   pharmacist : "",
  //   checkin_date: "",
  //   checkout_date: ""
  // });

  useEffect(() => {
    getUsers(activeData);
    getAllPharmacies();
    getAllPharmacists(pharmacist);
    getAllProvince();

  }, []);

  const handleChangePharmacy = (pharmacy_id) => {

    validation.setFieldValue("pharmacy_id", pharmacy_id);
    pharmacist.pharmacy_id = pharmacy_id;
    pharmacist.pharmacistdata = [];
    setPharmacist({ pharmacy_id: pharmacy_id, pharmacistdata: [] });
    getAllPharmacists(pharmacist);
  };

  const getAllPharmacists = (pharmacist) => {
    PharmacistsAPI.getAllPharmacistsList(pharmacist)
      .then((res) => {
        setPharmacist({ pharmacistdata: res.data.pharmacist.rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllProvince = () => {
    ProvinceAPI.getProvincesList()
      .then((res) => {
        setProvince(res.data.provinces.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPharmacies = () => {
    PharmacyListAPI.getAllPharmacies()
      .then((res) => {
        setPharmacies({ pharmaciydata: res.data.pharmacy.rows, pharmaciyalldata: res.data.pharmacy.rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeProvince = (province_id) => {
    validation.setFieldValue("province_id", province_id);
    const pharmaciydata = pharmacies.pharmaciydata;
    const pharmaciyalldata = pharmacies.pharmaciyalldata;
    if (province_id != '') {
      var filteredArray = pharmaciyalldata.filter(function (itm) {
        return itm.province_id == province_id;
      });
    } else {
      var filteredArray = pharmaciyalldata;
    }
    setPharmacies({ pharmaciydata: filteredArray, pharmaciyalldata: pharmaciyalldata });
  };

  const dateFormat = (date) => {
    var d = date.replace('.000Z', '');
    return moment(d).format("DD/MM/YYYY");
    // moment(yourDate).format("YYYY/MM/DD kk:mm:ss")
    // return moment(date).format("DD/MM/YYYY");


  };

  const timeFormat = (time) => {
    // moment(yourDate).format("YYYY/MM/DD kk:mm:ss")
    console.log(time);
    var t = time.replace('.000Z', '')
    return moment(t).format("hh:mm A");
  };

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.srno,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Pharmacist"),
      selector: (row) => row.pharmacist,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Photo"),
      selector: row => row.img_url,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Pharmacy"),
      selector: (row) => row.pharmacy,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Date"),
      selector: (row) => row.date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Time"),
      selector: (row) => row.checkin_time,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type"),
      selector: (row) => row.checkout_time,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ];

  const getUsers = (data) => {
    setLoading(true);
    CheckinCheckoutAPI.getAllCheckins(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.response.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.response.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          // console.log(dateFormat(v.checkin_time, "d-m-yyyy"));
          data[i] = {
            // srno: ++i,
            srno: sr_no,
            pharmacist: v.pharmacist?.name_en,
            img_url: (
              <>
                {
                  v.img_url ?
                    <a href="#!"
                      onClick={() => viewPhoto(v.img_url)}
                    >
                      <img
                        className=" header-profile-user"
                        src={v.img_url}
                      />
                    </a> :
                    <a href="#!"
                      onClick={() => viewPhoto(pharmacist_image)}
                    >
                      <img
                        className=" header-profile-user"
                        src={pharmacist_image}
                      />
                    </a>

                }
              </>
            ),
            pharmacy: v.pharmacy?.pharmacy_details?.pharmacy_name,
            date: dateFormat(v.date_time),
            checkin_time: timeFormat(v.date_time),
            checkout_time: v.type,
            // mobile: v.mobile,
            action: (
              <>
                <Link to={`/user-profile/${v.id}`}>
                  {" "}
                  <i className="uil-eye font-size-20"></i>
                </Link>
              </>
            ),
          };
        });
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getUsers(data);
  };

  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getUsers(data);
  };

  const viewPhoto = async (imgPath) => {
    Swal.fire({
      imageUrl: imgPath,
      imageHeight: 400,
      imageWidth: 400,
      imageAlt: 'image'
    })
  };

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      province_id: '',
      pharmacy_id: '',
      pharmacist_id: '',
      start_date: '',
      end_date: '',
    },
    validationSchema: Yup.object({
      start_date: Yup.date(),
      end_date: Yup.date().min(Yup.ref('start_date'), 'End date should be a greater than start date'),
    }),
    onSubmit: (values) => {
      activeData.activePage = 1;
      activeData.totalPage = 1;
      activeData.search = values;
      activeData.limit = 10;
      setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
      getUsers(activeData);

    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Tables")}
            breadcrumbItem={props.t("Check-in/Check-out List")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Province")}
                          </Label>
                          <Input
                            name="province_id"
                            type="select"
                            className="form-control"
                            //onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.province_id || ""}
                            onChange={(e) => handleChangeProvince(e.target.value)}
                          >
                            <option value="">
                              {props.t("Select Province")}
                            </option>

                            {provinces.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name_en}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Pharmacy")}
                          </Label>
                          <Input
                            name="pharmacy_id"
                            type="select"
                            className="form-control"
                            //onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pharmacy_id || ""}
                            onChange={(e) => handleChangePharmacy(e.target.value)}
                          >
                            <option value="">
                              {props.t("Select Pharmacy")}
                            </option>
                            {pharmacies.pharmaciydata.map((item) => (
                              <option key={item.id} value={item.user_id}>
                                {item.pharmacy_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Pharmacist")}
                          </Label>
                          <Input
                            name="pharmacist_id"
                            type="select"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pharmacist_id || ""}
                          // onChangePage={(value) => handleChangePharmacist(value)}
                          >
                            <option value="">
                              {props.t("Select Pharmacist")}
                            </option>
                            {pharmacist.pharmacistdata.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name_en}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Start Date")}
                          </Label>
                          <Input
                            name="start_date"
                            placeholder={props.t("Enter News And Events Date")}
                            type="date"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.start_date || ""}
                          // onChangePage={(value) => handleChangeStartdate(value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("End Date")}
                          </Label>
                          <Input
                            name="end_date"
                            placeholder={props.t("Enter News And Events Date")}
                            type="date"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.end_date || ""}
                            invalid={
                              validation.touched.end_date &&
                                validation.errors.end_date
                                ? true
                                : false
                            }
                          // onChangePage={(value) => handleChangeEnddate(value)}
                          />
                        </FormGroup>

                        <div style={{ color: 'red' }}>{validation.errors.end_date}</div>


                      </Col>
                      <Col md="2" className="mt-4" style={{ paddingTop: 5 }}>
                        <Button color="primary"
                        // onClick={handleClickCustomSearch()}
                        >
                          {props.t("Search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <DataTableExtensions
                    columns={columns}
                    data={users}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      // columns={columns}
                      // data={roles}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(ChechinCheckout);
