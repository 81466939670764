import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  FormFeedback,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PharmacistsAPI } from "../../apis/PharmacistsAPI";
import { PharmacyListAPI } from "../../apis/PharmacyListAPI";
import { ProvinceAPI } from "../../apis/ProvinceAPI";
import DataTable from "react-data-table-component";
import config from "../../config";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import pharmacist_image from "../../assets/images/flags/pharmacist image.jpg";

const PharmacistsList = (props) => {
  const [pharmacists, setPharmacists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: {
      province_id: '',
      pharmacy_id: '',
      pharmacist_id: '',
      start_date: '',
      end_date: '',
    },
    limit: 10,
  });
  const [pharmacies, setPharmacies] = useState({ pharmaciydata: [], pharmaciyalldata: [] });
  const [pharmacist, setPharmacist] = useState({ pharmacy_id: '', pharmacistdata: [] });
  const [provinces, setProvince] = useState([]);

  useEffect(() => {
    getPharmacists(activeData);
    getAllPharmacies();
    getAllProvince();
  }, []);

  const getAllProvince = () => {
    ProvinceAPI.getProvincesList()
      .then((res) => {
        setProvince(res.data.provinces.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllPharmacies = () => {
    PharmacyListAPI.getAllPharmacies()
      .then((res) => {
        setPharmacies({ pharmaciydata: res.data.pharmacy.rows, pharmaciyalldata: res.data.pharmacy.rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: props.t("Sr.No"),
      selector: (row) => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Photo"),
      selector: (row) => row.thumbnail,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Pharmacist Name"),
      selector: (row) => row.pharmacy_name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Email"),
      selector: (row) => row.email,
      sort: "asc",
      width: 200,
    },
    {
      name: props.t("Mobile"),
      selector: (row) => row.mobile,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ];

  const getPharmacists = (data) => {
    setLoading(true);
    PharmacistsAPI.getAllPharmacists(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.pharmacists.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.pharmacists.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          data[i] = {
            sr_no: sr_no,
            // thumbnail: ++i,
            thumbnail: (
              <>
                <img
                  className=" header-profile-user"
                  src={pharmacist_image}

                />
              </>
            ),
            pharmacy_name: v.name_en,
            email: v.email,
            mobile: v.mobile,
          };
        });
        setPharmacists(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    });
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    };
    getPharmacists(data);
  };
  const handleChangeProvince = (province_id) => {
    validation.setFieldValue("province_id", province_id);
    const pharmaciydata = pharmacies.pharmaciydata;
    const pharmaciyalldata = pharmacies.pharmaciyalldata;
    if (province_id != '') {
      var filteredArray = pharmaciyalldata.filter(function (itm) {
        return itm.province_id == province_id;
      });
    } else {
      var filteredArray = pharmaciyalldata;
    }
    setPharmacies({ pharmaciydata: filteredArray, pharmaciyalldata: pharmaciyalldata });
  };




  const handleRowChange = (v) => {
    setActiveData({
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    });
    const data = {
      activePage: activeData.activePage,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    };
    getPharmacists(data);
  };
  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      province_id: '',
      pharmacy_id: '',

    },

    onSubmit: (values) => {
      activeData.activePage = 1;
      activeData.totalPage = 1;
      activeData.search = values;
      activeData.limit = 10;
      setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
      getPharmacists(activeData);
    }
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Tables")}
            breadcrumbItem={props.t("Pharmacist Report")}
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Province")}
                          </Label>
                          <Input
                            name="province_id"
                            type="select"
                            className="form-control"
                            //onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.province_id || ""}
                            onChange={(e) => handleChangeProvince(e.target.value)}
                          >
                            <option value="">
                              {props.t("Select Province")}
                            </option>

                            {provinces.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name_en}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Pharmacy")}
                          </Label>
                          <Input
                            name="pharmacy_id"
                            type="select"
                            className="form-control"
                            onBlur={validation.handleBlur}
                            value={validation.values.pharmacy_id || ""}
                            onChange={validation.handleChange}
                          >
                            <option value="">
                              {props.t("Select Pharmacy")}
                            </option>
                            {pharmacies.pharmaciydata.map((item) => (
                              <option key={item.id} value={item.user_id}>
                                {item.pharmacy_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md="2" className="mt-4" style={{ paddingTop: 5 }}>
                        <Button color="primary"
                        >
                          {props.t("Search")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <DataTableExtensions
                    columns={columns}
                    data={pharmacists}
                    print={false}
                    export={false}
                    filter={false}
                  >
                    <DataTable
                      className="table-bordered"
                      noDataComponent={props.t('There are no records to display')}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={(value) => handleRowChange(value)}
                      onChangePage={(value) => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(PharmacistsList);
