import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const BannerAPI = {
    getBannerList: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createBanner: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/create-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getBanner: async (banner_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-banner/${banner_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    updateBanner: async (data) => {
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/admin/update-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteBanner: async (banner_id) => {
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}/api/admin/delete-banner/${banner_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },

    getBannerListPharmacyOwner: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createBannerPharmacyOwner: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/create-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getBannerPharmacyOwner: async (banner_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/pharmacy/fetch-banner/${banner_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    updateBannerPharmacyOwner: async (data) => {
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}/api/pharmacy/update-banner`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteBannerPharmacyOwner: async (banner_id) => {
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}/api/pharmacy/delete-banner/${banner_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }

}