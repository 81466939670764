import React, { useEffect, useState } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    CardSubtitle,
} from "reactstrap";
import Swal from "sweetalert2";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
// import { PharmacyTypeAPI } from "../../apis/PharmacyTypeAPI";
import { PharmacyTypeAPI } from "../../apis/PharmacyTypeAPI";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next"

const PharmacyTypeList = (props) => {

    const history = useHistory();

    const [pharmacy_type_list, setPharmacyTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name_en, setNameEn] = useState("");
    const [name_th, setNameTh] = useState("");
    const [pharmacy_type_id, setPharmacyType] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: 10,
    });

    useEffect(() => {
        getPharmacyTypeList(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: (row) => row.id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Name (Eng)"),
            selector: (row) => row.name_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Name (Thai)"),
            selector: (row) => row.name_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
          name: props.t("Action"),
          selector: (row) => row.action,
          sort: "asc",
          width: 200,
        },
    ];

    const getPharmacyTypeList = (data) => {
        setLoading(true);
        PharmacyTypeAPI.getAllPharmacyTypeList(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.pharmacyType.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res.data.pharmacyType.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        // srno: ++i,
                        id: sr_no,
                        name_en: v.name_en,
                        name_th: v.name_th,
                        action: (
                          <>
                            <i
                              style={{ marginRight: "15px", color: "#7b8190" }}
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              onClick={() => onEdit(v.id)}
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              style={{ color: "#7b8190" }}
                              id="deletetooltip"
                              onClick={() => onDelete(v.id)}
                            />
                          </>
                        ),
                    };
                });
                setPharmacyTypeList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are you sure?"),
            text: props.t("You won't be able to revert this!"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes, delete it!"),
        }).then((result) => {
            if (result.isConfirmed) {
                PharmacyTypeAPI.deletePharmacyType(id)
                    .then((res) => {
                        getPharmacyTypeList(activeData);
                        window.location.reload(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (pharmacy_type_id) => {
        PharmacyTypeAPI.getPharmacyType(pharmacy_type_id)
            .then((res) => {
                console.log(res)
                setNameEn(res.data.pharmacyType.name_en);
                setNameTh(res.data.pharmacyType.name_th);
                setPharmacyType(res.data.pharmacyType.id);
                getPharmacyTypeList(activeData);

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        });
        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        };
        getPharmacyTypeList(data);
    };

    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        };
        getPharmacyTypeList(data);
    };

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name_en: name_en,
            name_th: name_th,
        },
        validationSchema: Yup.object({
            name_en: Yup.string().required(props.t("Please Enter Your Pharmacy Type  Name (Eng)")).matches(/^[aA-zZ\s]+$/, props.t("Only alphabets are allowed for this field ")),
            name_th: Yup.string().required(props.t("Please Enter Your Pharmacy Type  Name (Thai)")),
        }),
        onSubmit: (values) => {
            if (pharmacy_type_id) {
                values.pharmacy_type_id = pharmacy_type_id;
                PharmacyTypeAPI.updatePharmacyType(values)
                    .then((res) => {
                        // setLoader(false)
                        Swal.fire({
                            text: props.t("Pharmacy Type Updated Successfully"),
                            icon: "success",
                            imageAlt: "success image",
                            // confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push("/pharmacy-type");
                                getPharmacyTypeList(activeData);
                                window.location.reload(false);
                            }
                        });
                    })
                    .catch(function (error) {
                        // setLoader(false)
                        Swal.fire({
                            text: error.response.data.message,
                            icon: "error",
                            imageAlt: "error image",
                            // confirmButtonColor: '#00CA84'
                        });
                    });
            } else {
                PharmacyTypeAPI.createPharmacyType(values)
                    .then((res) => {
                        // setLoader(false)
                        Swal.fire({
                            text: props.t("Pharmacy Type Saved Successfully"),
                            icon: "success",
                            imageAlt: "success image",
                            // confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push("/pharmacy-type");
                                getPharmacyTypeList(activeData);
                                window.location.reload(false);
                            }
                        });
                    })
                    .catch(function (error) {
                        // setLoader(false)
                        Swal.fire({
                            text: error.response.data.message,
                            icon: "error",
                            imageAlt: "error image",
                            // confirmButtonColor: '#00CA84'
                        });
                    });
            }
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Pharmacy Type List")} />
                    <Row>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                    <CardSubtitle className="mb-3">
                                        {props.t("Pharmacy Type List")}
                                    </CardSubtitle>

                                    {/* <MDBDataTable responsive bordered data={data} /> */}
                                    <DataTableExtensions 
                                    columns={columns} 
                                    data={pharmacy_type_list} 
                                    print={false} 
                                    filter={false}
                                    export={false}>
                                        <DataTable
                                            className="table-bordered"
                                            noDataComponent={props.t('There are no records to display')}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={(value) => handleRowChange(value)}
                                            onChangePage={(value) => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Type (Eng)")}</Label>
                                                    <Input
                                                        name="name_en"
                                                        placeholder={props.t("Pharmacy Type (Eng)")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name_en || ""}
                                                        invalid={
                                                            validation.touched.name_en &&
                                                                validation.errors.name_en
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name_en &&
                                                        validation.errors.name_en ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name_en}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">{props.t("Pharmacy Type (Thai)")}</Label>
                                                    <Input
                                                        name="name_th"
                                                        placeholder={props.t("Pharmacy Type (Thai)")}
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name_th || ""}
                                                        invalid={
                                                            validation.touched.name_th &&
                                                                validation.errors.name_th
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.name_th &&
                                                        validation.errors.name_th ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.name_th}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {name_en && (
                                            <Button color="primary" type="submit">
                                                {props.t("Update")}
                                            </Button>
                                        )}
                                        {!name_en && (
                                            <Button color="primary" type="submit">
                                                {props.t("Add")}
                                            </Button>
                                        )}
                                        {"  "}
                                        <Button color="light" onClick={() => window.location.reload(false)}>
                                            {props.t("Cancel")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(PharmacyTypeList))
