import React, { useEffect, useState } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
    CardSubtitle,
} from "reactstrap";
import Swal from "sweetalert2";
// Formik validation

import { Link, useHistory } from "react-router-dom";
import { ComplaintAPI } from "../../apis/ComplaintAPI";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import config from "../../config";
import dateFormat from "dateformat";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as url from "../../helpers/common_helper";


const ComplaintList = (props) => {

    const history = useHistory();

    const [complaint_list, setComplaintList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: 10,
    });

    useEffect(() => {
        getComplaintList(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr.No"),
            selector: (row) => row.id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Customer Name"),
            selector: (row) => row.name_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Customer Phone"),
            selector: (row) => row.mobile,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Pharmacy Name"),
            selector: (row) => row.pharmacy_name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Date"),
            selector: (row) => row.created_at,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Action"),
            selector: (row) => row.action,
            sort: "asc",
            width: 200,
        },

    ];

    const getComplaintList = (data) => {
        setLoading(true);
        ComplaintAPI.getAllComplaint(data)
            .then((res) => {
                console.log(res.data)
                setActiveData({
                    activePage: activeData.activePage,
                    totalPage: res.data.complaint.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res.data.complaint.rows.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        // srno: ++i,
                        id: sr_no, 
                        name_en: v.customer.name_en,
                        mobile: v.customer.mobile,
                        pharmacy_name: v.pharmacy.pharmacy_details.pharmacy_name,
                        created_at: url.dateFormat(v.created_at),
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    to={`/complaint-profile/${v.id}`}
                                >
                                    <i className="mdi mdi-pencil font-size-18" style={{ color: "#7b8190" }} id="edittooltip" />
                                </Link>
                            </>
                        ),
                       
                    };
                });
                setComplaintList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const handleChange = (v) => {
        setActiveData({
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        });
        const data = {
            activePage: v,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: activeData.limit,
        };
        getComplaintList(data);
    };

    const handleRowChange = (v) => {
        setActiveData({
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        });
        const data = {
            activePage: activeData.activePage,
            totalPage: activeData.totalPage,
            search: activeData.search,
            limit: v,
        };
        getComplaintList(data);
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            customer_name: "",
            start_date:"",
            end_date:""
        },
        validationSchema: Yup.object({
            // search_keyword: Yup.string().required()

        }),
        onSubmit: (values) => {
            activeData.activePage = 1;
            activeData.totalPage = 1;
            activeData.search = values;
            activeData.limit = 10;
            setActiveData({ activePage: 1, totalPage: 1, search: values, limit: 10 });
            getComplaintList(activeData);

        }
    });



    return (
        <React.Fragment> 
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Complaint List")} />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <CardSubtitle className="mb-3">
                                        {props.t("Complaint List")}
                                    </CardSubtitle>

                                    <Form className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>

                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">
                                                        {props.t("Customer Name")}
                                                    </Label>
                                                    <Input
                                                        name="customer_name"
                                                        placeholder={props.t("Customer Name")}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.customer_name || ""}
                                                    // onChangePage={(value) => handleChangeStartdate(value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                    {props.t("Start Date")}
                                                </Label>
                                                <Input
                                                    name="start_date"
                                                    placeholder={props.t("Start Date")}
                                                    type="date"
                                                    className="form-control"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.start_date || ""}
                                                // onChangePage={(value) => handleChangeStartdate(value)}
                                                />
                                                </FormGroup>
                                            </Col>


                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                    {props.t("End Date")}
                                                </Label>
                                                <Input
                                                    name="end_date"
                                                    placeholder={props.t("End Date")}
                                                    type="date"
                                                    className="form-control"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.end_date || ""}
                                                // onChangePage={(value) => handleChangeStartdate(value)}
                                                />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" className="mt-4" style={{ paddingTop: 5 }}>
                                                <Button color="primary"
                                                // onClick={handleClickCustomSearch()}
                                                >
                                                    {props.t("Search")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    {/* <MDBDataTable responsive bordered data={data} /> */}
                                    <DataTableExtensions 
                                    columns={columns} 
                                    data={complaint_list}
                                    filter={false}
                                     print={false} export={false}>
                                        <DataTable
                                            className="table-bordered"
                                            noDataComponent={props.t('There are no records to display')}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={(value) => handleRowChange(value)}
                                            onChangePage={(value) => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(ComplaintList))
