import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const SubDistrictAPI = {
    // getAllSubDistrict: async () => {
    //     var axiosConfig = {
    //         method: 'get',
    //         url: `${config.API_URL}/api/admin/fetch-sub-district`,
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-Type': 'application/json'
    //         }
    //     };

    //     return await axios(axiosConfig);
    // },
    getAllSubDistrictByProvinceAndDistricts: async (district_id,province_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-sub-district-by-province-and-district/${district_id}/${province_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    getAllSubDistrictByProvinceAndDistrict: async (district_id,province_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/pharmacy/fetch-sub-district-by-province-and-district/${district_id}/${province_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    }
}