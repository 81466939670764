import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { UserAPI } from "../../apis/UserAPI";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";

const UserView = (props) => {
  const user_id = props.match.params.user_id;
  const history = useHistory();
  const [name, setName] = useState("");
  const [code_id, setCodeId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [avatar_image, setAvatarImage] = useState("");
  const [avatar_unique_name, setAvatarUniqueName] = useState("");
  const [country_en, setCountryEn] = useState("");
  const [province_en, setProvinceEn] = useState("");
  const [district_en, setDistrictEn] = useState("");
  const [sub_district_en, setSubDistrictEn] = useState("");

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    UserAPI.getUser(user_id)
      .then((res) => {
        setName(res.data.user.name);
        setCodeId(res.data.user.code_id);
        setEmail(res.data.user.email);
        setMobile(res.data.user.mobile);
        setAvatarImage(res.data.user.avatar_image);
        setAvatarUniqueName(res.data.user.avatar_unique_name);
        setCountryEn(res.data.user.Country.country_en);
        setProvinceEn(res.data.user.Province.province_en);
        setDistrictEn(res.data.user.District.district_en);
        setSubDistrictEn(res.data.user.SubDistrict.sub_district_en);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Users")} breadcrumbItem={props.t("Profile Detail")}/>
          <Row className="mb-4">
            <Col className="col-12">

              <Button style={{ float: "right", marginBottom: "20px", }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                {props.t("Back")}
              </Button>

            </Col>
            <Col xl="12">
              <Card className="card h-100">
                <CardBody>
                  <div className="text-center">
                    <div>
                      <img
                        src={avatar_image}
                        alt=""
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                    </div>
                    <h5 className="mt-3 mb-1">{name}</h5>
                    <p className="text-muted">{name}</p>
                  </div>
                  <Row>
                    <Col xl="6">
                      <p className="mb-1">{props.t("Code ID")}:</p>
                      <h5 className="font-size-16">{code_id}</h5>
                    </Col>
                    <Col xl="6">
                      <div>
                        <p className="mb-1">{props.t("Email")} :</p>
                        <h5 className="font-size-16">{email}</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p className="mb-1">{props.t("Mobile")}  :</p>
                      <h5 className="font-size-16">{mobile}</h5>
                    </Col>
                    <Col xl="6">
                      <div>
                        <p className="mb-1">{props.t("Avatar Unique Name")} :</p>
                        <h5 className="font-size-16">{avatar_unique_name}</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p className="mb-1">{props.t("Country (Eng)")} :</p>
                      <h5 className="font-size-16">{country_en}</h5>
                    </Col>
                    <Col xl="6">
                      <div>
                        <p className="mb-1">{props.t("Province (Eng)")} :</p>
                        <h5 className="font-size-16">{province_en}</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <p className="mb-1">{props.t("District (Eng)")} :</p>
                      <h5 className="font-size-16">{district_en}</h5>
                    </Col>
                    <Col xl="6">
                      <div>
                        <p className="mb-1">{props.t("Sub District (Eng)")}  :</p>
                        <h5 className="font-size-16">{sub_district_en}</h5>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default (withTranslation()(UserView))