import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import EditPrivacy from "../pages/PrivacyPolicy/EditPrivacy";

import ForgetPwd from "../pages/Authentication/ForgetPassword";

// User
import UserList from "../pages/User/UserList";
import UserView from "../pages/User/UserView";

import ChechinCheckout from "../pages/Checkins/ChechinCheckout"

// Pharmacy 
import PharmacyList from "../pages/Pharmacy/PharmacyList"
import OffInLInePharmacyList from "../pages/Pharmacists/OffInLInePharmaciestList"
import AddPharmacy from "../pages/Pharmacy/AddPharmacy"
import EditPharmacy from "../pages/Pharmacy/EditPharmacy";
import ViewPharmacy from "../pages/Pharmacy/ViewPharmacy";
// Pharmacy End

// Pharmacy Type
import PharmacyTypeList from "../pages/PharmacyType/PharmacyTypeList"
// Pharmacy Type End

// Pharmacists
import PharmacistsList from "../pages/Pharmacists/PharmacistsList"

import Rating from '../pages/RatingAndReview/Rating';
import GeneralSetting from '../pages/GeneralSetting/GeneralSetting';
import IdCardInstructionList from '../pages/IdCardInstruction/IdCardInstructionList';
import AddIdCardInstruction from '../pages/IdCardInstruction/AddIdCardInstruction';
import EditIdCardInstruction from '../pages/IdCardInstruction/EditIdCardInstruction';
import PharmacyDashboard from '../pages/Dashboard/PharmacyDashboard';
import EditPharmacyOwnerPharmacy from '../pages/Pharmacy/EditPharmacyOwnerPharmacy';
import UpdateBusinessHour from "../pages/Pharmacy/UpdateBusinessHour";
import PharmacyOwnerChechinCheckout from '../pages/Checkins/PharmacyOwnerChechinCheckout';
import PharmacyOwnerPharmacistsList from '../pages/Pharmacists/PharmacyOwnerPharmacistsList';
import PharmacyOwnerBannerList from '../pages/Banner/PharmacyOwnerBannerList';
import PharmacyOwnerRating from '../pages/RatingAndReview/PharmacyOwnerRating';
import QRCode from '../pages/QRCode/QRCodeGenerate';
import Complaint from '../pages/Complaint/ComplaintList';
import ComplaintProfile from '../pages/Complaint/ComplaintProfile';

//Reports
import UserReports from "../pages/Reports/UserReports"
import PharmacistsReports from "../pages/Reports/PharmacistsReports"
import PharmacyReports from "../pages/Reports/PharmacyReports"
import ChechinCheckoutReports from "../pages/Reports/ChechinCheckoutReports"
import BannerList from "../pages/Banner/BannerList"
import NonAuthLayout from '../components/NonAuthLayout';
import VerticalLayout from '../components/VerticalLayout';
import { getRole, isLoggedIn, Token } from "../Token";
import Setting from "../pages/Setting/Setting";


function getLayout() {
  let layoutCls = VerticalLayout;
  return layoutCls;
}
const Layout = isLoggedIn() ? getLayout() : NonAuthLayout;

const checkUser = () => {
  if (!isLoggedIn()) {
    window.location.href = '/login';
  } else if (isLoggedIn() && getRole() == 'Super Admin') {
    window.location.href = '/admin';
  } else if (isLoggedIn() && getRole() == 'Pharmacy Owner') {
    window.location.href = '/pharmacy';
  } else {
    window.location.href = '/';
  }
}

const isAdmin = isLoggedIn() && getRole() == 'Super Admin';
const isPhamcyOwner = isLoggedIn() && getRole() == 'Pharmacy Owner';

var userRoutes = [];
if (isAdmin) {
  userRoutes = [
     // { path: "/dashboard", component: Dashboard },
     isAdmin ? { path: "/admin", component: Dashboard } : checkUser(),
     //User Route
     isAdmin ?{ path: "/customers", component: UserList }: checkUser(),
     // isAdmin ?{ path: "/user-profile/:user_id", component: UserView }: checkUser(),
 
     //customer Route
     isAdmin ?{ path: "/checkin_checkout", component: ChechinCheckout }: checkUser(),
     // { path: "/user-profile/:user_id", component: UserView },
 
    
 
     // Pharmacy
     isAdmin ?{ path: "/pharmacies", component: PharmacyList }: checkUser(),
     isAdmin ?{ path: "/add-pharmacy", component: AddPharmacy }: checkUser(),
     isAdmin ?{ path: "/edit-pharmacy/:pharmacy_id", component: EditPharmacy }: checkUser(),
     //  isAdmin ?{ path: "/edit-pharmacy/:pharmacy_id", component: ViewPharmacy }: checkUser(),
     
     // Pharmacists
     isAdmin ?{ path: "/pharmacists", component: PharmacistsList }: checkUser(),
     isAdmin ?{ path: "/pharmacists/:offInStatus", component: OffInLInePharmacyList }: checkUser(),
    
 
     // Pharmacy Type
     isAdmin ?{ path: "/pharmacy-type", component: PharmacyTypeList }: checkUser(),
     
 
     // Reports.
     isAdmin ?{ path: "/customer_reports", component: UserReports }: checkUser(),
     isAdmin ?{ path: "/pharmacist_reports", component: PharmacistsReports }: checkUser(),
     isAdmin ?{ path: "/pharmacy_reports", component: PharmacyReports }: checkUser(),
     isAdmin ?{ path: "/checkin_checkout_reports", component: ChechinCheckoutReports }: checkUser(),
 
     // rating and review
     isAdmin ? { path: "/rating_and_reviews", component: Rating } : checkUser(),
 
     // GenralSetting 
     isAdmin ? { path: "/general_setting", component: GeneralSetting } : checkUser(),
 
     // Id Card Instruction 
 
     isAdmin ? { path: "/id-card-instructions", component: IdCardInstructionList } : checkUser(),
     isAdmin ? { path: "/add-id-card-instruction", component: AddIdCardInstruction } : checkUser(),
     isAdmin ? { path: "/edit-id-card-instruction/:id_card_instruction_id", component: EditIdCardInstruction } : checkUser(),
 
     // Pharmacy Type
     isAdmin ?{ path: "/complaint", component: Complaint }: checkUser(),
     isAdmin ? { path: "/complaint-profile/:complaint_id", component: ComplaintProfile } : checkUser(),

     { path: "/privacy-policy", component: PrivacyPolicy },
     { path: "/edit-page-content/:page_content_id", component: EditPrivacy },
     { path: "/setting", component: Setting },
 
     // this route should be at the end of all other routes
     { path: "/", exact: true, component: () => <Redirect to="/admin" /> },
     { path:"*", component: () => <Redirect to="/admin" />},

     
  ];
} else if(isPhamcyOwner){
  userRoutes = [
 isPhamcyOwner ? { path: "/pharmacy", component: PharmacyDashboard } : checkUser(),
    isPhamcyOwner ? { exact:true, path: "/pharmacy_list", component: EditPharmacyOwnerPharmacy } : checkUser(),
    isPhamcyOwner ? { exact:true, path: "/checkin_checkout_list", component: PharmacyOwnerChechinCheckout } : checkUser(),
    isPhamcyOwner ? {  exact:true, path: "/pharmacists_list", component: PharmacyOwnerPharmacistsList } : checkUser(),
    isPhamcyOwner ? {  exact:true, path: "/update-business-hour/:pharmacy_id", component: UpdateBusinessHour } : checkUser(),
    isPhamcyOwner ? { exact:true,path: "/banner_list", component: PharmacyOwnerBannerList } : checkUser(),
    isPhamcyOwner ? { exact:true,path: "/pharmacy/customers", component: UserList } : checkUser(),
    isPhamcyOwner ? { exact:true,path: "/rating_and_reviews_list", component: PharmacyOwnerRating } : checkUser(),
    isPhamcyOwner ? { exact:true,path: "/qr_code", component: QRCode } : checkUser(),
    { path: "/", exact: true, component: () => <Redirect to="/pharmacy" /> },
    { path:"*", component: () => <Redirect to="/pharmacy" />},
  ];
}else {
  <Redirect to="/login" />;
}

var authRoutes = [];
if (!isLoggedIn()) {
  authRoutes = [
    { path: "/", component: Login },
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/register", component: Register },
    

    { path: "/pages-maintenance", component: PagesMaintenance },
    { path: "/pages-comingsoon", component: PagesComingsoon },
    { path: "/pages-404", component: Pages404 },
    { path: "/pages-500", component: Pages500 },
  ];
} else {
  <Redirect to="/dashboard" />;
}

export { userRoutes, authRoutes };
