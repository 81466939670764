import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const ComplaintAPI = {
    getAllComplaint: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-complaint`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    fetchComplaint: async (complaint_id) => {
        const token = getToken();
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}/api/admin/fetch-complaint-detail/${complaint_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },

}