import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { BusinessHourAPI } from "../../apis/BusinessHourAPI";
import TimeInput from "react-time-picker-input";

import "./TimeInput.css"



import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

const UpdateBusinessHour = (props) => {

    const [check, setCheck] = useState(false);

    const [dateEx1, setDateEx1] = useState("10:10")

    const pharmacy_id = useParams().pharmacy_id;
    console.log(pharmacy_id, 'pharmacy_id')
    const [business_hour, setBusinessHour] = useState([]);
    const [monday_open_close, setMondayOpenClose] = useState();
    const [monday_open_close_value, setMondayOpenCloseValue] = useState();
    const [monday_start_time, setMondayStartTime] = useState('');
    const [monday_end_time, setMondayEndTime] = useState('');

    const [tuesday_open_close, setTuesdayOpenClose] = useState();
    const [tuesday_open_close_value, setTuesdayOpenCloseValue] = useState();
    const [tuesday_start_time, setTuesdayStartTime] = useState('');
    const [tuesday_end_time, setTuesdayEndTime] = useState('');

    const [wednesday_open_close, setWednesdayOpenClose] = useState();
    const [wednesday_open_close_value, setWednesdayOpenCloseValue] = useState();
    const [wednesday_start_time, setWednesdayStartTime] = useState('');
    const [wednesday_end_time, setWednesdayEndTime] = useState('');

    const [thursday_open_close, setThursdayOpenClose] = useState();
    const [thursday_open_close_value, setThursdayOpenCloseValue] = useState();
    const [thursday_start_time, setThursdayStartTime] = useState('');
    const [thursday_end_time, setThursdayEndTime] = useState('');

    const [friday_open_close, setFridayOpenClose] = useState();
    const [friday_open_close_value, setFridayOpenCloseValue] = useState();
    const [friday_start_time, setFridayStartTime] = useState('');
    const [friday_end_time, setFridayEndTime] = useState('');

    const [saturday_open_close, setSaturdayOpenClose] = useState();
    const [saturday_open_close_value, setSaturdayOpenCloseValue] = useState();
    const [saturday_start_time, setSaturdayStartTime] = useState('');
    const [saturday_end_time, setSaturdayEndTime] = useState('');

    const [sunday_open_close, setSundayOpenClose] = useState();
    const [sunday_open_close_value, setSundayOpenCloseValue] = useState();
    const [sunday_start_time, setSundayStartTime] = useState('');
    const [sunday_end_time, setSundayEndTime] = useState('');
    const history = useHistory();



    useEffect(() => {
        getBusinessHour()
    }, [])

    const getBusinessHour = () => {
        BusinessHourAPI.getAllBusinessHour(pharmacy_id)
            .then(res => {
                setBusinessHour(res.data.businessHour);
                setMondayOpenClose(
                    <div className="form-check form-switch form-switch-lg col-md-3">
                        <input
                            type="checkbox"
                            name="monday_open_close"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            onChange={e => handleChange(e)}
                            defaultChecked={res.data.businessHour[0].is_open == "true" ? true : false}



                        />
                        {res.data.businessHour[0].is_open == "true" ? "Open" : "Closed"}
                    </div>)
                setMondayOpenCloseValue(res.data.businessHour[0].is_open == "true" ? true : false)

                setTuesdayOpenClose(
                    <div className="form-check form-switch form-switch-lg col-md-3">
                        <input
                            type="checkbox"
                            name="tuesday_open_close"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            onChange={e => handleChange(e)}
                            defaultChecked={res.data.businessHour[1].is_open == "true" ? true : false}



                        />
                        {res.data.businessHour[1].is_open == "true" ? "Open" : "Closed"}
                    </div>)
                setTuesdayOpenCloseValue(res.data.businessHour[1].is_open == "true" ? true : false)

                setWednesdayOpenClose(
                    <div className="form-check form-switch form-switch-lg col-md-3"><input
                        type="checkbox"
                        name="wednesday_open_close"
                        className="form-check-input"
                        id="customSwitchsizelg"
                        onChange={e => handleChange(e)}
                        defaultChecked={res.data.businessHour[2].is_open == "true" ? true : false}



                    />{res.data.businessHour[2].is_open == "true" ? "Open" : "Closed"}
                    </div>)
                setWednesdayOpenCloseValue(res.data.businessHour[2].is_open == "true" ? true : false)

                setThursdayOpenClose(<div className="form-check form-switch form-switch-lg col-md-3"><input
                    type="checkbox"
                    name="thursday_open_close"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={e => handleChange(e)}
                    defaultChecked={res.data.businessHour[3].is_open == "true" ? true : false}



                />{res.data.businessHour[3].is_open == "true" ? "Open" : "Closed"}
                </div>)
                setThursdayOpenCloseValue(res.data.businessHour[3].is_open == "true" ? true : false)

                setFridayOpenClose(<div className="form-check form-switch form-switch-lg col-md-3"><input
                    type="checkbox"
                    name="friday_open_close"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={e => handleChange(e)}
                    defaultChecked={res.data.businessHour[4].is_open == "true" ? true : false}



                />{res.data.businessHour[4].is_open == "true" ? "Open" : "Closed"}
                </div>)
                setFridayOpenCloseValue(res.data.businessHour[4].is_open == "true" ? true : false)

                setSaturdayOpenClose(<div className="form-check form-switch form-switch-lg col-md-3"><input
                    type="checkbox"
                    name="saturday_open_close"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={e => handleChange(e)}
                    defaultChecked={res.data.businessHour[5].is_open == "true" ? true : false}



                />{res.data.businessHour[5].is_open == "true" ? "Open" : "Closed"}
                </div>)
                setSaturdayOpenCloseValue(res.data.businessHour[5].is_open == "true" ? true : false)

                setSundayOpenClose(<div className="form-check form-switch form-switch-lg col-md-3"><input
                    type="checkbox"
                    name="sunday_open_close"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={e => handleChange(e)}
                    defaultChecked={res.data.businessHour[6].is_open == "true" ? true : false}



                />{res.data.businessHour[6].is_open == "true" ? "Open" : "Closed"}
                </div>)
                setSundayOpenCloseValue(res.data.businessHour[6].is_open == "true" ? true : false)


            }).catch(err => {
                console.log(err)
            })
    }
    const timeFormat = (time) => {
        return moment(time, 'HH:mm:ss').format('HH:mm');
    };
    const onChange = (event) => {

        if (event.target.name == "monday_start_time") {

            setMondayStartTime(event.target.value)
        }
        if (event.target.name == "monday_end_time") {

            setMondayEndTime(event.target.value)
        }

        if (event.target.name == "tuesday_start_time") {
            setTuesdayStartTime(event.target.value)
        }
        if (event.target.name == "tuesday_end_time") {

            setTuesdayEndTime(event.target.value)
        }

        if (event.target.name == "wednesday_start_time") {

            setWednesdayStartTime(event.target.value)
        }
        if (event.target.name == "wednesday_end_time") {

            setWednesdayEndTime(event.target.value)
        }

        if (event.target.name == "thursday_start_time") {

            setThursdayStartTime(event.target.value)
        }
        if (event.target.name == "thursday_end_time") {

            setThursdayEndTime(event.target.value)
        }

        if (event.target.name == "friday_start_time") {

            setFridayStartTime(event.target.value)
        }
        if (event.target.name == "friday_end_time") {

            setFridayEndTime(event.target.value)
        }

        if (event.target.name == "saturday_start_time") {

            setSaturdayStartTime(event.target.value)
        }
        if (event.target.name == "saturday_end_time") {

            setSaturdayEndTime(event.target.value)
        }

        if (event.target.name == "sunday_start_time") {

            setSundayStartTime(event.target.value)
        }
        if (event.target.name == "sunday_end_time") {

            setSundayEndTime(event.target.value)
        }

        // if (event.target.value.length) {
        //     setMondayStartTime(event.target.value )
        // }
    }
    const handleChange = (e) => {
        let isChecked = e.target.checked;
        if (e.target.name == "monday_open_close") {
            setMondayOpenCloseValue(current => !current);

        }
        if (e.target.name == "tuesday_open_close") {
            setTuesdayOpenCloseValue(current => !current);
        }

        if (e.target.name == "wednesday_open_close") {

            setWednesdayOpenCloseValue(current => !current);
        }

        if (e.target.name == "thursday_open_close") {

            setThursdayOpenCloseValue(current => !current);
        }

        if (e.target.name == "friday_open_close") {

            setFridayOpenCloseValue(current => !current);
        }

        if (e.target.name == "saturday_open_close") {

            setSaturdayOpenCloseValue(current => !current);
        }

        if (e.target.name == "sunday_open_close") {

            setSundayOpenCloseValue(current => !current);
        }
        // do whatever you want with isChecked value
    }

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            monday_open_close: monday_open_close_value ? monday_open_close_value : false,
            // monday_start_time: monday_start_time ? monday_start_time : business_hour[0] ? timeFormat(business_hour[0].start_time) : '',
            monday_start_time: monday_start_time != 'aN:--' ? monday_start_time : timeFormat(business_hour[0]?.start_time),
            // monday_end_time: monday_end_time ? monday_end_time : business_hour[0] ? timeFormat(business_hour[0].end_time) : '',
            monday_end_time: monday_end_time != 'aN:--' ? monday_end_time :  timeFormat(business_hour[0]?.end_time),
            tuesday_open_close: tuesday_open_close_value ? tuesday_open_close_value : false,
            // tuesday_start_time: tuesday_start_time ? tuesday_start_time : business_hour[1] ? timeFormat(business_hour[1].start_time) : '',
            tuesday_start_time: tuesday_start_time != 'aN:--' ? tuesday_start_time :  timeFormat(business_hour[1]?.start_time),
            tuesday_end_time: tuesday_end_time != 'aN:--' ? tuesday_end_time : timeFormat(business_hour[1]?.end_time),
            wednesday_open_close: wednesday_open_close_value ? wednesday_open_close_value : false,
            wednesday_start_time: wednesday_start_time != 'aN:--' ? wednesday_start_time : timeFormat(business_hour[2]?.start_time),
            wednesday_end_time: wednesday_end_time != 'aN:--' ? wednesday_end_time : timeFormat(business_hour[2]?.end_time),
            thursday_open_close: thursday_open_close_value ? thursday_open_close_value : false,
            thursday_start_time: thursday_start_time != 'aN:--' ? thursday_start_time : timeFormat(business_hour[3]?.start_time),
            thursday_end_time: thursday_end_time != 'aN:--' ? thursday_end_time : timeFormat(business_hour[3]?.end_time),
            friday_open_close: friday_open_close_value ? friday_open_close_value : false,
            friday_start_time: friday_start_time  != 'aN:--' ? friday_start_time : timeFormat(business_hour[4]?.start_time),
            friday_end_time: friday_end_time != 'aN:--' ? friday_end_time : timeFormat(business_hour[4]?.end_time),
            saturday_open_close: saturday_open_close_value ? saturday_open_close_value : false,
            saturday_start_time: saturday_start_time != 'aN:--' ? saturday_start_time : timeFormat(business_hour[5]?.start_time),
            saturday_end_time: saturday_end_time != 'aN:--' ? saturday_end_time : timeFormat(business_hour[5]?.end_time),
            sunday_open_close: sunday_open_close_value ? sunday_open_close_value : false,
            sunday_start_time: sunday_start_time != 'aN:--' ? sunday_start_time : timeFormat(business_hour[6]?.start_time) ,
            sunday_end_time: sunday_end_time != 'aN:--' ? sunday_end_time : timeFormat(business_hour[6]?.end_time),
            pharmacy_id: pharmacy_id,
            day_number_mon: 1,
            day_number_tue: 2,
            day_number_wed: 3,
            day_number_thu: 4,
            day_number_fri: 5,
            day_number_sat: 6,
            day_number_sun: 7,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {
            console.log(values.monday_start_time)
            BusinessHourAPI.updateBusinessHour(values)
                .then(res => {
                    // setLoader(false)
                    Swal.fire({
                        text: props.t("Business Hour Updated Successfully"),
                        icon: 'success',
                        imageAlt: 'success image',
                        // confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/pharmacy_list');
                        }
                    });
                }).catch(function (error) {
                    // setLoader(false)
                    Swal.fire({
                        text: error?.response?.data?.message,
                        icon: 'error',
                        imageAlt: 'error image',
                        // confirmButtonColor: '#00CA84'
                    });
                });

        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Forms")} breadcrumbItem={props.t("View Business Hours")} />
                    <Row>
                        <Col className="col-12">
                            <Button
                                style={{ float: "right", marginBottom: "20px" }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>
                        </Col>

                        <Col xl="12">
                            <Card>

                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Monday")}
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {monday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="monday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"
                                                    // onChange={(e)=>console.log(e.target)}
                                                    onChange={e => handleChange(e)}
                                                    // onBlur={validation.handleBlur}
                                                    // checked={business_hour[0] ? true : false}

                                                    defaultChecked={business_hour[0]?.is_open ? true : false}
                                                    value={monday_open_close}
                                                    // value={business_hour[0] ? business_hour[0].is_open : false}


                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:20}}>
                                                {/* <Input
                                                    name="monday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={monday_start_time ? monday_start_time : business_hour[0]?.is_open == 'true' ? timeFormat(business_hour[0].start_time) : ''}
                                                    className="form-control"
                                                // id="validationCustom01"
                                                /> */}
                                                <TimeInput value={business_hour[0]?.is_open == 'true' ? timeFormat(business_hour[0].start_time) : '--:--'}  hour12Format eachInputDropdown={true} manuallyDisplayDropdown={false}   onChange={(dateString) => setMondayStartTime(dateString)  } />

                                            </div>
                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:20}}>
                                                {/* <Input
                                                    name="monday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={monday_end_time ? monday_end_time : business_hour[0]?.is_open == 'true' ? timeFormat(business_hour[0].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                 <TimeInput    value={business_hour[0]?.is_open == 'true' ? timeFormat(business_hour[0].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setMondayEndTime(dateString)  } />
                                                
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Tuesday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {tuesday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="tuesday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"

                                                    // checked={tuesday_open_close}
                                                    value={business_hour[1] ? business_hour[1].is_open : ''}


                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:18}}>
                                                {/* <Input
                                                    name="tuesday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={tuesday_start_time ? tuesday_start_time : business_hour[1]?.is_open == 'true' ? timeFormat(business_hour[1].start_time) : ''}
                                                    // value={business_hour[1] ? timeFormat(business_hour[1].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                <TimeInput    value={business_hour[1]?.is_open == 'true' ? timeFormat(business_hour[1].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setTuesdayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:18}}>
                                                {/* <Input
                                                    name="tuesday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={tuesday_end_time ? tuesday_end_time : business_hour[1]?.is_open == 'true' ? timeFormat(business_hour[1].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                <TimeInput    value={business_hour[1]?.is_open == 'true' ? timeFormat(business_hour[1].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setTuesdayEndTime(dateString)  } />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Wednesday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {wednesday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="wednesday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"

                                                    // checked={wednesday_open_close}
                                                    value={business_hour[2] ? business_hour[2].is_open : ''}


                                                /> */}
                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:16}}>
                                                {/* <Input
                                                    name="wednesday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={wednesday_start_time ? wednesday_start_time : business_hour[2]?.is_open == 'true' ? timeFormat(business_hour[2].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                <TimeInput    value={business_hour[2]?.is_open == 'true' ? timeFormat(business_hour[2].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setWednesdayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:16}}>
                                                {/* <Input
                                                    name="wednesday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={wednesday_end_time ? wednesday_end_time : business_hour[2]?.is_open == 'true' ? timeFormat(business_hour[2].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                 <TimeInput    value={business_hour[2]?.is_open == 'true' ? timeFormat(business_hour[2].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setWednesdayEndTime(dateString)  } />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Thursday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {thursday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="thursday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"

                                                    // checked={thursday_open_close}
                                                    value={business_hour[3] ? business_hour[3].is_open : ''}


                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:14}}>
                                                {/* <Input
                                                    name="thursday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={thursday_start_time ? thursday_start_time : business_hour[3]?.is_open == 'true' ? timeFormat(business_hour[3].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                <TimeInput    value={business_hour[3]?.is_open == 'true' ? timeFormat(business_hour[3].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setThursdayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:14}}>
                                                {/* <Input
                                                    name="thursday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={thursday_end_time ? thursday_end_time : business_hour[3]?.is_open == 'true' ? timeFormat(business_hour[3].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                 <TimeInput    value={business_hour[3]?.is_open == 'true' ? timeFormat(business_hour[3].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setThursdayEndTime(dateString)  } />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Friday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {friday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="friday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"

                                                    // checked={friday_open_close}
                                                    value={business_hour[4] ? timeFormat(business_hour[4].start_time) : ''}


                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:12}}>
                                                {/* <Input
                                                    name="friday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={friday_start_time ? friday_start_time : business_hour[4]?.is_open == 'true' ? timeFormat(business_hour[4].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                <TimeInput    value={business_hour[4]?.is_open == 'true' ? timeFormat(business_hour[4].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setFridayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:12}}>
                                                {/* <Input
                                                    name="friday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={friday_end_time ? friday_end_time : business_hour[4]?.is_open == 'true' ? timeFormat(business_hour[4].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                <TimeInput    value={business_hour[4]?.is_open == 'true' ? timeFormat(business_hour[4].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setFridayEndTime(dateString)  } />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                 {props.t("Saturday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {saturday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="saturday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"
                                                    onChange={(e) => console.log(e.target.name)}

                                                    // checked={saturday_open_close}
                                                    value={business_hour[5] ? timeFormat(business_hour[5].is_open) : ''}



                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:10}}>
                                                {/* <Input
                                                    name="saturday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={saturday_start_time ? saturday_start_time : business_hour[5]?.is_open == 'true' ? timeFormat(business_hour[5].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                 <TimeInput    value={business_hour[5]?.is_open == 'true' ? timeFormat(business_hour[5].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setSaturdayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:10}}>
                                                {/* <Input
                                                    name="saturday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={saturday_end_time ? saturday_end_time : business_hour[5]?.is_open == 'true' ? timeFormat(business_hour[5].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                <TimeInput    value={business_hour[5]?.is_open == 'true' ? timeFormat(business_hour[5].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setSaturdayEndTime(dateString)  } />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-3 col-form-label"
                                            >
                                                {props.t("Sunday")}
                                                
                                            </label>
                                            {/* <div className="form-check form-switch form-switch-lg col-md-3"> */}
                                            {sunday_open_close}
                                            {/* <input
                                                    type="checkbox"
                                                    name="sunday_open_close"
                                                    className="form-check-input"
                                                    id="customSwitchsizelg"
                                                    onChange={(e) => console.log(e.target.name)}
                                                    // checked={sunday_open_close}
                                                    value={business_hour[6] ? timeFormat(business_hour[6].start_time) : ''}



                                                /> */}

                                            {/* Open
                                            </div> */}
                                            <div className="col-md-2" style={{zIndex:8}}>
                                                {/* <Input
                                                    name="sunday_start_time"
                                                    placeholder={props.t("Start time")}
                                                    type="time"
                                                    onChange={onChange}
                                                    value={sunday_start_time ? sunday_start_time : business_hour[6]?.is_open == 'true' ? timeFormat(business_hour[6].start_time) : ''}

                                                    className="form-control"
                                                // id="validationCustom01"



                                                /> */}
                                                <TimeInput    value={business_hour[6]?.is_open == 'true' ? timeFormat(business_hour[6].start_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setSundayStartTime(dateString)  } />
                                            </div>

                                            <div className="form-check form-switch form-switch-lg col-md-1" style={{ marginLeft: '-22px' }}>
                                                To
                                            </div>
                                            <div className="col-md-2" style={{zIndex:8}}>
                                                {/* <Input
                                                    name="sunday_end_time"
                                                    placeholder=""
                                                    type="time"
                                                    onChange={onChange}
                                                    value={sunday_end_time ? sunday_end_time : business_hour[6]?.is_open == 'true' ? timeFormat(business_hour[6].end_time) : ''}
                                                    className="form-control"
                                                    id="validationCustom01"

                                                /> */}
                                                <TimeInput    value={business_hour[6]?.is_open == 'true' ? timeFormat(business_hour[6].end_time) : '--:--'}  hour12Format eachInputDropdown={true}    onChange={(dateString) => setSundayEndTime(dateString)  } />
                                            </div>
                                        </Row>

                                        {/* <Button color="primary" type="submit">
                                            {props.t("Update")}
                                        </Button>


                                        {"  "}
                                        <Button color="light" onClick={() => history.goBack()}>
                                            {props.t("Cancel")}
                                        </Button> */}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default (withTranslation()(UpdateBusinessHour))