import config from "../config";
import axios from "axios"
import { getToken } from "../Token";

const token = getToken();
export const RatingAPI = {
    getPharmacyOwnerRatingAndReview: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/pharmacy/fetch-rating-and-review`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getRatingAndReview: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-rating-and-review`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getRatingByPharmacy: async (user_id,data) => {

        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}/api/admin/fetch-rating-and-review-by-pharmacy/${user_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    }


}